import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import Parse from 'parse'
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';

export const sendSubChannelNotification = (account: Parse.Object, recipientAccount: Parse.Object, collection: Parse.Object) => {
  // don't send notifications to following things
  if (account.id == recipientAccount.id) { return }

  const url = "/user/" + account.get("profileId")
  const description = account.get("profileId") + " followed \"" + collection.get("emoji") + " " + collection.get("name") +"\""
  return Parse.Cloud.run('sendNotification', { actorAccountId: account.id, recipientAccountId: recipientAccount.id, description, url });
}

export const sendCollectPostNotification = (account: Parse.Object, recipientAccount: Parse.Object, collection: Parse.Object) => {
  // don't send notifications to yourself collecting things
  if (account.id == recipientAccount.id) { return }

  const url = "/collection/" + collection.id
  const description = account.get("profileId") + " collected your post into " + collection.get("name")
  return Parse.Cloud.run('sendNotification', { actorAccountId: account.id, recipientAccountId: recipientAccount.id, description, url });
}

export const sendCommentNotification = (account: Parse.Object, recipientAccount: Parse.Object, postId: string, commentText: string) => {
  const url = "/post/" + postId;
  const title = account.get("profileId") + " responded";
  const description = account.get("profileId") + " commented: " + commentText;
  return Parse.Cloud.run('sendNotification', { actorAccountId: account.id, recipientAccountId: recipientAccount.id, description, url, title });
}

export const sendPostLikeNotification = (account: Parse.Object, recipientAccount: Parse.Object, postId: string) => {
  const url = "/post/" + postId;
  const description = account.get("profileId") + " liked your post";
  return Parse.Cloud.run('sendNotification', { actorAccountId: account.id, recipientAccountId: recipientAccount.id, description, url });
}

export const sendCommentLikeNotification = (account: Parse.Object, recipientAccount: Parse.Object, postId: string) => {
  const url = "/post/" + postId;
  const description = account.get("profileId") + " liked your comment";
  return Parse.Cloud.run('sendNotification', { actorAccountId: account.id, recipientAccountId: recipientAccount.id, description, url });
}

export const sendMentionNotification = (
  actorAccount: Parse.Object,
  recipientAccount: Parse.Object,
  postId: string
) => {
  const actorAccountId = actorAccount.id;
  const url = "/post/" + postId;
  const description = `${actorAccount.get("name")} mentioned you in a post`;
  return Parse.Cloud.run('sendNotification', { actorAccountId: actorAccountId, recipientAccountId: recipientAccount.id, description, url });
}

export const sendCommentMentionNotification = (
  actorAccount: Parse.Object,
  recipientAccountId: string,
  postId: string,
) => {
  const actorAccountId = actorAccount.id;
  const url = "/post/" + postId;
  const description = `${actorAccount.get("profileId")} mentioned you in a comment`;
  return Parse.Cloud.run('sendNotification', { actorAccountId, recipientAccountId: recipientAccountId, description, url });
}

export const useApplePushNotifications = (): void => {
  const navigator = useSeamNavigator();
  useEffect(() => {
    const platform = Capacitor.getPlatform();
    if (platform !== 'ios') return;
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      let url: string = (event.notification.data as any).url;
      mixpanel.track("tapped on ios notification")
      navigator.navigateTo(url);
    });
  }, []);
}