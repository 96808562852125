import React, { useState, useEffect } from "react";
import { useMobile } from "../utils/MobileContext";
import { Avatar, Typography } from "@mui/material";
import Parse from "parse";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import { useSeamUser } from "../utils/SeamUserContext";
import { Capacitor } from "@capacitor/core";
import { IonTabButton, IonTabBar, IonTabs, useIonRouter } from "@ionic/react";
import { motion } from "framer-motion";
import Composer from "../Composer/Composer";
import "./MobileBottomNav.css";
import { Groups } from "@mui/icons-material";
import { useScrolling } from "./ScrollingContext";
import FloatingSignInButton from "./FloatingSignInButton";

const tabVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export default function MobileBottomNav({ children }) {
  const [keyboardShowing, setKeyboardShowing] = useState(false);
  const [currentTab, setCurrentTab] = useState("home");
  const { isMobile, isMobileApp } = useMobile();
  const { isScrolling } = useScrolling();
  const router = useIonRouter();
  const { account } = useSeamUser();
  const isAuthenticated = Parse.User.current() != undefined;
  const currentAccountProcessedProfilePhoto = getProcessedProfilePictureURL(
    account?.get("profilePhoto")
  );
  const isScreenshot = router.routeInfo.pathname.includes("/preview/");
  const hideBottomNav =
    router.routeInfo.pathname.includes("/post/") ||
    router.routeInfo.pathname.includes("/comment/") ||
    isScreenshot;

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") {
      return;
    }

    const handleKeyboardDidShow = (ev) => {
      setKeyboardShowing(true);
    };

    const handleKeyboardDidHide = () => {
      setKeyboardShowing(false);
    };

    window.addEventListener("ionKeyboardDidShow", handleKeyboardDidShow);
    window.addEventListener("ionKeyboardDidHide", handleKeyboardDidHide);

    return () => {
      window.removeEventListener("ionKeyboardDidShow", handleKeyboardDidShow);
      window.removeEventListener("ionKeyboardDidHide", handleKeyboardDidHide);
    };
  }, []);

  const showSignInButton =
    !isAuthenticated &&
    !isMobileApp &&
    !account && 
    !isScreenshot;

  const showMobileNav =
    (isMobileApp || isMobile) &&
    isAuthenticated &&
    account &&
    account.get("isOnboarded") === true


  const mobileTabsVisible = !keyboardShowing && !hideBottomNav;
  const tabBarOpacity = isScrolling ? 0 : 1;

  if (showSignInButton) {
    return (
      <FloatingSignInButton children={children} mobileTabsVisible={mobileTabsVisible} tabBarOpacity={tabBarOpacity} isMobile={isMobile} />
    )
  }

  else if (showMobileNav) {
    return (
      <IonTabs
        onIonTabsDidChange={(event) => {
          setCurrentTab(event.detail.tab);
        }}
        style={{ marginTop: "env(safe-area-inset-top)", }}
      >
        {children}
        <IonTabBar
          slot="bottom"
          style={{
            visibility: mobileTabsVisible ? "visible" : "hidden",
            paddingBlock: '4px',
            paddingInline: '2px',
            borderRadius: '68px',
            opacity: tabBarOpacity,
            transition: 'opacity 0.3s ease',
          }}
          className="flex flex-row items-center justify-center h-[70px] w-[222px]"
        >
          <IonTabButton className="w-[70px] h-[70px]" href={isMobileApp ? "/homeTab" : "/home"} tab="home">
            <div className="relative flex justify-center items-center w-[70px] h-[70px] bg-black rounded-full">
              <motion.div
                initial={currentTab === "home" ? "visible" : "hidden"}
                animate={currentTab === "home" ? "visible" : "hidden"}
                variants={tabVariants}
                transition={{ duration: 0.3 }}
                className="bg-white absolute h-[70px] w-[70px] rounded-full"
              />
              <Groups
                className={`z-10 transition-colors duration-300 ${
                  currentTab === "home" ? "text-black" : "text-white"
                }`}
              />
            </div>
          </IonTabButton>
          <IonTabButton className="w-[70px] h-[70px]">
            <div style={{backgroundImage: "linear-gradient(167deg, #FB3939 -1.46%, #EE39FB 108.77%)" }} className="relative flex flex-row items-center rounded-full h-[70px] w-[70px] justify-center">
              <Composer />
            </div>
          </IonTabButton>
          <IonTabButton className="w-[70px] h-[70px]" tab="profile" href="/profileTab">
            <div className="relative flex justify-center bg-black items-center w-[70px] h-[70px] rounded-full">
              <motion.div
                initial={currentTab === "profile" ? "visible" : "hidden"}
                animate={currentTab === "profile" ? "visible" : "hidden"}
                variants={tabVariants}
                transition={{ duration: 0.3 }}
                className="absolute bg-white rounded-full h-[70px] w-[70px]"
              />
              <Avatar
                src={currentAccountProcessedProfilePhoto}
                style={{
                  width: 30,
                  height: 30,
                  border: "2px solid white",
                  zIndex: 1
                }}
              />
            </div>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    )
  }

  else return children;
}