import Parse from 'parse';
import { Colors } from "../utils/colors"
import { SetStateAction, useEffect, useState } from "react";
import { Typography, Avatar, Box, TextField, IconButton, Tooltip } from "@mui/material";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { useSeamUser } from "../utils/SeamUserContext";
import TomeUserItem from "../components/TomeUserItem";

interface BlockedUserPage {

}

export default function BlockedUserPage(
  { }: BlockedUserPage
) {
  const { account } = useSeamUser();

  const [blockedUsers, setBlockedUsers] = useState<Parse.Object[]>([])

  useEffect(() => {
    if (account == undefined) { return }
    const blockedArray = account?.get("blockedUsers") ?? []
    if (blockedArray.length === 0) { return }

    const blockedUsersQuery = new Parse.Query("Account")
    blockedUsersQuery.containedIn("objectId", blockedArray.map((user: Parse.Object) => user.id));

    blockedUsersQuery.find().then(function (results) {
      setBlockedUsers(results)
    })
      .catch(function (error) {
        console.log(error)
        Sentry.captureException(error);
      })
  }, [account]);

  if (blockedUsers.length == 0) {
    return <Typography>{"Nothing here - you haven't blocked anyone."}</Typography>
  }

  const tapAction = (connection: Parse.Object) => {
    // turning off navigation for now because it feels weird to navigate to a blocked user's profile
    // seamNavigator.navigateTo(connection.get("profileId"))
  }

  return (
    <div className="flex flex-col text-center items-start w-full">
      {blockedUsers.map((connection, index) => (
        <div className="w-full mb-4">
          <TomeUserItem
            user={connection}
            title={<h3>@{connection.get("profileId")}</h3>}
            subtitle={<h4>{connection.get("name")}</h4>}
            link={`/user/${connection.get("profileId")}`}
            actions={
              <div 
                onClick={(event) => {
                  event.stopPropagation();
                  if (window.confirm("Unblock " + connection.get("profileId") + "?")) {
                    setBlockedUsers(blockedUsers.filter((user) => user.id != connection.id))
                    account?.remove("blockedUsers", connection);
                    account?.save()
                      .then((updatedAccount: any) => {
                        mixpanel.track("unblocked_user")
                        console.log('Blocked user removed:', updatedAccount);
                      }, (error: any) => {
                        console.error('Error while removing blocked user:', error);
                      });
                  }
                }}
                className="flex flex-row items-center justify-center px-2 py-1 space-x-1 bg-seam-red rounded-[20px] "
              >
                <h4 className="text-seam-white">Unblock</h4>
            </div>}
          />
        </div>
      ))}
    </div>
  );
}