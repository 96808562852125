import React, { useState, useEffect, memo } from 'react';
import Parse from 'parse';
import { ChevronRight, DeleteForever, Close, ChevronLeft } from '@mui/icons-material';
import { Divider, ToggleButton, IconButton, Typography, Button } from '@mui/material';
import { useMobile } from '../utils/MobileContext';
import { useSeamUser } from '../utils/SeamUserContext';
import ImageUploadComponent from '../components/ImageUploadComponent';
import BlockedUserPage from '../Routes/BlockedUserPage';
import BlockedTagsPage from '../Routes/BlockedTagsPage';
import mixpanel from 'mixpanel-browser';
import { usePrivy } from '@privy-io/react-auth';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import { useHistory } from 'react-router';
import { getProcessedProfilePictureURL } from '../components/helpers/ImageTransformers';
import { IOSSwitch } from '../components/IOSSwitch'
import GiphyButtonComponent from '../components/GiphyButtonComponent';
import { ChromePicker, HuePicker } from 'react-color';

const ProfileSettingsModal = ({ profileCustomizations, updateProfile, close }) => {
  const { logout, user } = usePrivy();
  const { account, setAccount } = useSeamUser();
  const { isMobileApp } = useMobile();
  const seamNavigator = useSeamNavigator();
  const history = useHistory();
  const [activeSection, setActiveSection] = useState("customize");
  const [profilePicture, setProfilePicture] = useState(account.get("profilePhoto"));
  const [color1, setColor1] = useState(profileCustomizations?.get("color1") || '#FAFAFA');
  const [color2, setColor2] = useState(profileCustomizations?.get("color2") || undefined);
  const [profileBackground, setProfileBackground] = useState(profileCustomizations?.get("backgroundImage") || null);
  const [backgroundStyle, setBackgroundStyle] = useState(profileCustomizations?.get("backgroundStyle") || null);
  const [fontColor, setFontColor] = useState(profileCustomizations?.get("fontColor") || "black");
  const [activeColorPicker, setActiveColorPicker] = useState(null); 

  function HeaderLabel({ activeSection }) {
    let label = "";
    switch (activeSection) {
      case "blockedAccounts":
        label = "Blocked Accounts";
        break;
      case "blockedTags":
        label = "Blocked Tags";
        break;
      default:
        label = "Edit Profile";
    }
    return (
      <h3 className="w-auto text-center mx-auto">{label}</h3>
    );
  }

  const handleColorChange = (color, colorKey) => {
    const selectedColor = color === 'white' || color === 'black' ? color : color.hex;
  
    if (colorKey === 'color1') {
      setColor1(selectedColor);
    } else if (colorKey === 'color2') {
      setColor2(selectedColor);
    }
  };
  
  const saveProfile = async () => {
    let updatedCustomizations = profileCustomizations;
  
    if (!updatedCustomizations) {
      updatedCustomizations = new Parse.Object("ProfileCustomizations");
      updatedCustomizations.set("account", account);
      updatedCustomizations.set("creatorId", account.get("profileId"));
    }
  
    updatedCustomizations.set("backgroundImage", profileBackground);
    updatedCustomizations.set("backgroundStyle", backgroundStyle);
    updatedCustomizations.set("color1", color1);
    updatedCustomizations.set("color2", color2); 
    updatedCustomizations.set("fontColor", fontColor);
    const savedCustomizations = await updatedCustomizations.save();
  
    updateProfile(savedCustomizations);
    mixpanel.track("Profile customization saved", { "account": account });
    account.set("profilePhoto", profilePicture)
    await account.save();
    Parse.Cloud.run("generateProfileScreenshot", { profileId: account.get("profileId") });
    setActiveSection("customize");
    close();
  };  

  const saveBackground = async (url) => {
    setProfileBackground(url);
    setBackgroundStyle(null);  // Clear background gradient when an image is selected
    
    const updateCustomizations = profileCustomizations ?? new Parse.Object("ProfileCustomizations");
    updateCustomizations.set("account", account);
    updateCustomizations.set("creatorId", account.get("profileId"));
    updateCustomizations.set("backgroundImage", url);
    updateCustomizations.set("backgroundStyle", null);  // Remove the background style
    const updated = await updateCustomizations.save();
    updateProfile(updated);
  };  

  // Function to delete the background image (set to null)
  const deleteBackground = async () => {
    setProfileBackground(null);

    const updateCustomizations = profileCustomizations ?? new Parse.Object("ProfileCustomizations");
    updateCustomizations.set("account", account);
    updateCustomizations.set("creatorId", account.get("profileId"));
    updateCustomizations.set("backgroundImage", null); 
    const updated = await updateCustomizations.save();
    updateProfile(updated);
  };

  const handleBackButton = () => {
    if (
      activeSection === "blockedAccounts" ||
      activeSection === "blockedTags"
    ) {
      // If we're in blocked accounts or tags, go back to settings
      setActiveSection("settings");
    } else if (activeSection === "settings") {
      setActiveSection("customize");
    } else if (activeSection === "customize") {
      close();
    }
  };

  const handleFontColorChange = async () => {
    const newFontColor = fontColor === "white" ? "black" : "white";
    setFontColor(newFontColor);
    const updateCustomizations = profileCustomizations ?? new Parse.Object("ProfileCustomizations");
    updateCustomizations.set("fontColor", newFontColor);
    const updated = await updateCustomizations.save();
    updateProfile(updated);
  };

  const toggleColorPicker = (colorKey) => {
    if (activeColorPicker === colorKey) {
      setActiveColorPicker(null);
    } else {
      setActiveColorPicker(colorKey);
    }
  };

  const CustomizeSection = () => {
    const subtitleStyle = "text-seam-gray-subtitle font-normal text-sm py-2";
    return (
      <div className="flex flex-col w-full h-full items-start justify-start space-y-4 mt-4">
        <div className="flex flex-col items-start justify-center text-center">
          <h3 className="text-seam-black">@{account?.get("profileId")}</h3>
        </div>
  
        {/* Profile Photo Upload */}
        <h4 className={subtitleStyle}>Profile Photo</h4>
        <div className="flex flex-row space-x-4 items-start justify-start h-[64px]">
          <div className="w-[64px] h-[64px]">
            <ImageUploadComponent
              fileTypes="image/*"
              onUpdate={(url) => setProfilePicture(url)}
              backgroundImageUrl={getProcessedProfilePictureURL(profilePicture)}
              fileName={account?.get("userId")}
            />
          </div>
          <div className="flex h-[64px] w-[64px] items-center justify-start">
              <GiphyButtonComponent onUpdate={(url) => setProfilePicture(url)} />
          </div>
        </div>
  
        {/* Profile Background Upload */}
        <h4 className={subtitleStyle}>Profile Background</h4>
        <div className="flex flex-row items-center w-full space-x-4">
          <div className="w-[64px] h-[90px]">
            <ImageUploadComponent
              fileTypes="image/*"
              basePath="profileBackgrounds"
              onUpdate={(url) => saveBackground(url)}
              backgroundImageUrl={profileBackground}
              fileName={account?.get("userId")}
            />
          </div>
          {/* Background gradient color pickers */}
          <div
            className={`flex w-full flex-col items-center justify-center border-[1px] rounded-[8px] h-[90px]`}
            style={{ backgroundColor: color1 }}
            onClick={() => toggleColorPicker('color1')} // Toggle for color 1
          >
            <Typography variant="h3" className="text-seam-black">Pick Color 1</Typography>
          </div>

          <div
            className={`flex w-full flex-col items-center justify-center border-[1px] rounded-[8px] h-[90px]`}
            style={{ backgroundColor: color2 }}
            onClick={() => toggleColorPicker('color2')} // Toggle for color 2
          >
            <Typography variant="h3" className="text-seam-black">Pick Color 2</Typography>
          </div>
          {/* Delete Button (shown only when there's a background image) */}
          {profileBackground && (
            <IconButton onClick={deleteBackground} aria-label="delete" size="large">
              <DeleteForever />
            </IconButton>
          )}
        </div>
        {activeColorPicker && (
          <div className="my-4 w-full flex flex-col items-start justify-start self-center">
            <h4 className={subtitleStyle}>Editing Color {activeColorPicker === 'color1' ? '1' : '2'}</h4>
            <div className="flex flex-col items-center justify-between w-full h-auto space-y-4">
              <HuePicker
                width='100%'
                color={activeColorPicker === 'color1' ? color1 : color2} // Check which color to update
                onChangeComplete={(color) => handleColorChange(color, activeColorPicker)}
              />
              
              <div className="flex flex-row items-center justify-between w-full h-auto space-x-6">
                  {/* White Button */}
                  <div
                    className="rounded-[8px] w-full flex items-center justify-center h-6 bg-white border-[1px] border-seam-black/50 cursor-pointer"
                    onClick={() => handleColorChange('white', activeColorPicker)}
                  >
                    <Typography variant="h4" className="text-seam-black/50 text-center">White</Typography>
                  </div>
                  {/* Black Button */}
                  <div
                    className="rounded-[8px] flex items-center justify-center w-full h-6 bg-black border-[1px] border-seam-white cursor-pointer"
                    onClick={() => handleColorChange('black', activeColorPicker)}
                  >
                    <Typography variant="h4" className="text-seam-white text-center">Black</Typography>
                  </div>
                </div>
            </div>
          </div>
        )}
        {/* Font Color Switch */}
        <h4 className={subtitleStyle}>Light Font</h4>
        <IOSSwitch checked={fontColor === "white"} onChange={handleFontColorChange} />
        <div className="flex flex-col w-full h-auto" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom, 24px) + 24px)', position: 'fixed', bottom: 0, left: 0, right: 0, p: 3, bgcolor: 'background.paper', boxShadow: 3, zIndex: 1301 }}>
          <Divider className="w-full h-auto" />
          <div
            className="flex flex-row place-content-between py-4 items-center mx-4"
            onClick={() => setActiveSection("settings")}
          >
            <div className="space-y-1">
              <h3 className="text-seam-black">Account Settings</h3>
              <h4 className="text-seam-black opacity-40">
                All the important stuff.
              </h4>
            </div>
            <div>
              <ChevronRight size={24} />
            </div>
          </div>
          <Divider className="w-full h-auto" />
        </div>
      </div>
    );
  }

  const SettingsSection = () => (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="mt-4">
          <div
            className="flex flex-row place-content-between py-4 items-center mt-4"
            onClick={() => setActiveSection("blockedAccounts")}
          >
            <div className="space-y-1">
              <h3 className="text-seam-black">Blocked Accounts</h3>
              <h4 className="text-seam-black opacity-40">
                People you said you didn't want to see anymore.
              </h4>
            </div>
            <div>
              <ChevronRight size={24} />
            </div>
          </div>
          <Divider />
          <div
            className="flex flex-row place-content-between py-4 items-center mt-4"
            onClick={() => setActiveSection("blockedTags")}
          >
            <div className="space-y-1">
              <h3 className="text-seam-black">Blocked Tags</h3>
              <h4 className="text-seam-black opacity-40">
                Tags that make you say: "I've seen enough."
              </h4>
            </div>
            <div>
              <ChevronRight size={24} />
            </div>
          </div>
          <Divider />
          <div
            className="flex flex-row place-content-between py-4 items-center mt-4"
            onClick={() => window.open("https://getseam.xyz/information-pages/bug-reports", "_blank")}
          >
            <div className="space-y-1">
              <h3 className="text-seam-black">Bug Reports</h3>
              <h4 className="text-seam-black opacity-40">
                If you see something, please tell us.
              </h4>
            </div>
            <div>
              <ChevronRight size={24} />
            </div>
          </div>
          <Divider />
        </div>
      </div>
      <div className="flex flex-col space-y-4 mb-7">
        <LogOutButton />
        <DeleteAccountButton />
      </div>
    </div>
  );

  const BlockedAccountsSection = () => {
    return (
      <div className="flex flex-col justify-between h-full w-full">
        <div className="w-full">
          <BlockedUserPage />
        </div>
      </div>
    );
  };

  const BlockedTagsSection = () => {
    return (
      <div className="flex flex-col justify-between h-full">
        <div>
          <BlockedTagsPage />
        </div>
      </div>
    );
  };

  const runLogout = async () => {
    window.emitter.emit("SEAM_EVENT_IS_LOADING", true);
    mixpanel.track("Log Out");
    let _ = await logout();
    let __ = await Parse.User.logOut();
    if (isMobileApp) {
      history.replace("login");
    } else {
      seamNavigator.navigateTo("/login");
      window.location.reload();
    }
    close();
  };

  const LogOutButton = () => (
    <div
      className="w-full max-h-[56px] text-[16px] text-center bg-white border-2 border-seam-red rounded-[8px] py-4 cursor-pointer"
      onClick={async () => {
        if (window.confirm("Are you sure you want to log out?")) {
          await runLogout();
        }
      }}
      role="button"
      aria-label="Log Out"
    >
      <h3 className="text-seam-red">Log out</h3>
    </div>
  );

  const DeleteAccountButton = () => (
    <div
      className="w-full max-h-[56px] text-[16px] text-center bg-seam-red border-2 border-seam-red rounded-[8px] py-4 cursor-pointer"
      onClick={() => {
        let userInput = window.prompt(
          "Please type 'delete account' to confirm deletion. \n\nWARNING: Everything you have created on Seam will be deleted PERMANENTLY (posts, comments, collections, etc)."
        );
        if (userInput === "delete account") {
          mixpanel.track("Delete Account");
          Parse.Cloud.run("deleteUser", {
            handle: account?.get("profileId"),
            privyDID: user?.id,
          });
          runLogout();
        } else {
          alert("Account deletion cancelled.");
        }
      }}
    >
      <h3 className="text-white">Delete account</h3>
    </div>
  );

  const ModalHeader = () => (
    <div className="flex relative flex-row h-[32px] w-full items-center justify-between cursor-pointer">
      <div
        className="rounded-full h-[32px] w-[32px] flex items-center justify-center cursor-pointer bg-[#EFEFEF]"
        onClick={() => {
          handleBackButton(activeSection);
        }}
      >
        {/* Show ChevronLeft when activeSection is not "customize", otherwise show Close icon */}
        {activeSection !== "customize" ? (
          <ChevronLeft className="w-4 h-4" color={"#101010"} />
        ) : (
          <Close className="w-4 h-4" color={"#101010"} />
        )}
      </div>
  
      <div className="w-auto" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
        <HeaderLabel activeSection={activeSection} />
      </div>
  
      {/* Only show the Save button when activeSection is "customize" */}
      {activeSection === "customize" && (
        <div
          className="bg-seam-green px-4 py-2 cursor-pointer rounded-[68px]"
          onClick={async () => {
            await saveProfile();  // Save the rest of the profile settings
          }}
        >
          Save
        </div>
      )}
    </div>
  );  

  return (
    <div className="flex flex-col justify-between w-auto items-start h-full p-4 box-border">
      <ModalHeader />
      <div className="py-4 h-full w-full">
        {activeSection === "customize" && <CustomizeSection />}
        {activeSection === "settings" && <SettingsSection />}
        {activeSection === "blockedAccounts" && <BlockedAccountsSection />}
        {activeSection === "blockedTags" && <BlockedTagsSection />}
      </div>
    </div >
  );
};

export default ProfileSettingsModal;