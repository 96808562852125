import React, { useState, useEffect } from "react";
import Parse from "parse";
import FeedItem from "./FeedItem";
import { useSeamUser } from "../utils/SeamUserContext";
import { LoadingSkeleton } from "../components/LoadingSkeleton";
import { Virtuoso } from "react-virtuoso";

export default function AdminReportedPostsFeed() {
  const POSTS_PER_PAGE = 10;
  const { account } = useSeamUser();
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    // optimistically remove deleted posts
    window.emitter.on("SEAM_EVENT_DELETE_POST", (post) => {
      setLoadedPosts(prevPosts => prevPosts.filter(prevPost => prevPost.id != post));
    });
  }, []);

  async function loadMore() {
    const query = new Parse.Query("Post");
    query.notEqualTo("reported", []);
    query.notEqualTo("isDeleted", true);
    query.descending("createdAt");
    query.include("author");
    query.limit(POSTS_PER_PAGE);
    query.skip(loadedPosts.length);
    const results = await query.find();

    setLoadedPosts(prevPosts => [...prevPosts, ...results]);

    if (results.length < POSTS_PER_PAGE) {
      setHasMore(false);
    }
    setLoading(false);
  }

  return (
    <div className="flex flex-col w-full h-full">
      <Virtuoso 
        style={{ height: window.innerHeight - 56 }}
        className={`ion-content-scroll-host`}
        data={loadedPosts}
        endReached={() => loadMore}
        overscan={3}
        itemContent={(index, post) => (
          <div key={post.id} style={{ width: "100%", paddingTop: 12 }}>
            <FeedItem post={post} />
          </div>
        )}
      />
    </div>
  )
}