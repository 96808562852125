import { differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';

export const formatPostDate = (postCreationDate) => {
    const now = new Date();
    const minutesDiff = differenceInMinutes(now, postCreationDate);
    const hoursDiff = differenceInHours(now, postCreationDate);
    const daysDiff = differenceInDays(now, postCreationDate);

    if (minutesDiff < 30) return "just now";
    if (minutesDiff < 60) return `${minutesDiff} min ago`;
    if (hoursDiff < 24) return `${hoursDiff} hr ago`;
    if (daysDiff < 2) return `${daysDiff} day ago`;
    if (daysDiff < 7) return `${daysDiff} days ago`
    if (daysDiff < 365) return postCreationDate?.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    return postCreationDate?.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
}