import React, { useEffect, useState } from "react";
import Parse from "parse";
import FeedItem from "./FeedItem";
import { useMobile } from "../utils/MobileContext";
import { Typography } from "@mui/material";
import { useSeamUser } from "../utils/SeamUserContext";
import { Virtuoso } from "react-virtuoso";

const SearchFeed = ({ tagName }) => {
  const { isMobile, isMobileApp } = useMobile();
  const { account } = useSeamUser();
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const POSTS_PER_PAGE = 10;
  const decodedTagName = decodeURIComponent(tagName);

  useEffect(() => {
    // Reset state for new search term
    setPage(0);
    setPosts([]);
    setHasMore(true);

    // Now fetchPostsByTag will be called indirectly via the page effect
  }, [decodedTagName]);

  useEffect(() => {
    const fetchPostsByTag = async () => {
      try {
        const result = await Parse.Cloud.run("fetchPostsByTag", {
          tagName: decodedTagName,
          blockedTags: account.get("blockedTags"),
          skip: page * POSTS_PER_PAGE,
          limit: POSTS_PER_PAGE,
        });
        const postsArray = Array.isArray(result) ? result : [];
        if (postsArray.length < POSTS_PER_PAGE) {
          setHasMore(false);
        }
        setPosts((prevPosts) => [...prevPosts, ...postsArray]);
      } catch (error) {
        console.error("Failed to fetch posts by tag: ", error);
      }
    };

    fetchPostsByTag();
  }, [page, decodedTagName]);

  const loadMorePosts = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
      <Virtuoso
        style={isMobileApp ? { height: window.innerHeight - 110 } : { height: window.innerHeight }}
        className={`ion-content-scroll-host`}
        data={posts}
        endReached={loadMorePosts}
        overscan={3}
        itemContent={(index, post) => {
          return (
            <div className="mb-4 w-full max-w-[720px] m-auto pt-4 px-4">
              <FeedItem
                key={post.id}
                post={post}
                ownerAccount={post.get("author")}
              />
            </div>
          );
        }}
      />
  );
};

export default SearchFeed;
