import { Avatar } from "@mui/material";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { getProcessedProfilePictureURL } from "./helpers/ImageTransformers";

const UsernameWithAvatar = ({ account, color, avatarSize }) => {
  const seamNavigator = useSeamNavigator();
  const processedAvatar = account && getProcessedProfilePictureURL(account.get('profilePhoto'));
  return (
    <div 
      onClick={() => seamNavigator.navigateTo(`/user/${account?.get("profileId")}`)} 
      className="flex self-start flex-row space-x-2 items-center justify-center cursor-pointer w-auto h-auto"
    >
      <Avatar src={processedAvatar} style={{ height: `${avatarSize}px`, width: `${avatarSize}px` }} />
      <h2 className={`text-${color} text-center overflow-visible truncate my-0`}>
        {account.get('profileId')}
      </h2>
    </div>
  );
};  

export default UsernameWithAvatar;