import { Mention, MentionsInput } from 'react-mentions';
import { Avatar, Typography } from "@mui/material";
import Parse from 'parse';
import { memo, useEffect, useRef, useState } from 'react';

const searchUsers = async (query, callback) => {
  try {
    const results = await Parse.Cloud.run('searchUsers', { searchTerm: query });
    callback(results.map(user => ({
      id: user.accountId,
      display: user.username,
      name: user.name,
      profilePhoto: user.profilePhoto
    })));
  } catch (error) {
    console.error('Error during search:', error);
  }
};

const MentionSearch = ({ textValue, mentionList, onChange, addMention, placeholder = "", inputRef = null, onSubmit }) => {

  const handleKeyDown = (event) => {
    // Check if Enter is pressed without Shift
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent default to stop the newline
      onSubmit(); // Call the provided submit function
    }
  };

  const handleAddMention = (mentionId) => {
    if (mentionList.length > 10) {
      console.warn("Maximum of 10 mentions reached");
      return;
    }

    const uniqueMentions = new Set(mentionList.map(mention => mention.id));

    if (uniqueMentions.has(mentionId)) {
      console.warn("This user is already mentioned");
      return;
    }

    addMention(mentionId);
  };

  return (
    <div style={{ width: '100%' }}>
      <MentionsInput
        value={textValue}
        onChange={onChange}
        placeholder={"Say something...."}
        style={{
          border: '1px solid #ccc',
          fontSize: '16px',
          fontWeight: 'normal',
          fontFamily: "Public Sans, sans-serif",
          padding: '8px 12px',
          borderRadius: '4px',
          display: 'block',
          width: '100%',
          resize: 'none',
          minHeight: '80px',
          outline: 'none',
        }}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        classNames={{
          suggestions: 'suggestionsList',
          suggestion: 'suggestionItem',
        }}
        allowSuggestionsAboveCursor={true}
        customSuggestionsContainer={(children) => <div style={{ border: '2px solid black' }}>{children}</div>}
      >
        <Mention
          trigger="@"
          markup="@[__display__]"
          data={searchUsers}
          onAdd={handleAddMention}
          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 16px',
              backgroundColor: focused ? '#e6e6e6' : '#f9f9f9',
            }}>
              <Avatar alt={suggestion.name} src={suggestion.profilePhoto} />
              <div style={{ marginLeft: '10px', color: 'black', fontSize: '16px', fontFamily: 'Public Sans, sans-serif' }}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', fontFamily: 'Public Sans, sans-serif', fontSize: '16px' }}>@{suggestion.display}</Typography>
                <Typography variant="body2">{suggestion.name}</Typography>
              </div>
            </div>
          )}
          displayTransform={(_, display) => `@${display}`}
        />
      </MentionsInput>
    </div>
  )
};

export default memo(MentionSearch);