import { MoreHoriz } from '@mui/icons-material';
import TomeCollectionRepository from '../Channels/TomeCollectionRepository';
import { useSeamUser } from '../utils/SeamUserContext';
import { useContext, useEffect, useState } from 'react';

const ChannelStats = ({ currentChannel, handleModalOpen, darkMode, takingScreenshot }) => {  
  const { account, refreshAccount, login } = useSeamUser();
  const [isFollowing, setIsFollowingChannel] = useState(false);
  const subscribers = currentChannel?.get("subscribers") || 0;
  const fontColor = currentChannel?.get("fontColor") || "black";
  const isChannelCreator = currentChannel?.get("creator")?.id === account?.id;
  const posts = currentChannel?.get("numPosts") || 0;

  let color = darkMode ? 'white' : 'black';
  let bgColor = darkMode ? 'black' : 'white';

  const fetchFollowingStatus = async () => {
    if (!account || !currentChannel?.id) return;
    const followedChannelsRelation = account.relation("followedChannels");
    const followedQuery = followedChannelsRelation.query();
    followedQuery.equalTo("objectId", currentChannel.id);
    const isFollowingChannel = await followedQuery.first();
    setIsFollowingChannel(!!isFollowingChannel);
  };

  useEffect(() => {
    fetchFollowingStatus();
  }, [account]);
  
  const handleFollow = async () => {
    if (!account) {
      login();
      return;
    }
    setIsFollowingChannel(true);
    try {
      await TomeCollectionRepository.subscribeToChannel(currentChannel, account, () => {
        refreshAccount();
      }, (error) => {
        console.error("Error subscribing to channel:", error);
      });
    } catch (error) {
      console.error("Subscription error:", error);
    }
  };

  const handleUnfollow = async () => {
    if (!account) {
      login();
      return;
    }
    setIsFollowingChannel(false);
    try {
      await TomeCollectionRepository.unsubscribeToChannel(currentChannel, account, () => {
        refreshAccount();
      }, (error) => {
        console.error("Error unsubscribing from channel:", error);
      });
    } catch (error) {
      console.error("Unsubscription error:", error);
    }
  };

  if (takingScreenshot) return;
  
  return (
    <div className="flex flex-col items-center justify-center w-full h-auto space-y-2">
      <span className="flex flex-row items-center justify-center">
        {subscribers > 1 && (
          <>
            <span className="flex flex-row items-center justify-center">
              <h4 className={`text-${color}`}>{subscribers}</h4>&nbsp;
              <h4 className={`text-${color} text-opacity-[40%]`}>Followers</h4>
            </span>
            <h4 className={`w-[18px] h-4 text-center text-${color}`}>•</h4>
          </>
        )}
        <span className="flex flex-row items-center justify-center">
          <h4 className={`text-${color}`}>{posts}</h4>&nbsp;
          <h4 className={`text-${color} text-opacity-[40%]`}>Posts</h4>
        </span>
        {isChannelCreator ? (
          <div
            onClick={() => handleModalOpen()}
            className={`rounded-full ml-4 bg-${bgColor} flex items-center justify-center border border-black bg-opacity-[10%] border-opacity-[10%] w-[30px] h-[30px]`}
          >
            <MoreHoriz className={`w-[10px] h-[10px] fill-${color}`} />
          </div>
        ) : (
          <button
            onClick={isFollowing ? handleUnfollow : handleFollow}
            className={`py-2 px-4 ml-4 bg-${darkMode ? '[#0E51CE]' : '[#0051E8]'} text-white rounded-[38px]`}
          >
            <span className="flex flex-row items-center justify-center space-x-2">
              <h4>{isFollowing ? "Unfollow" : "Follow"}</h4>
            </span>
          </button>
        )}
      </span>
    </div>
  );
};

export default ChannelStats;