import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { BadgeTypes } from "../Badges/BadgeTypes";
import QuestManager from "../Quests/QuestManager";
import { QuestTypes } from "../Quests/QuestTypes";
import { useSeamUser } from "../utils/SeamUserContext";
import ProfilePageFollowButton from "./ProfilePageFollowButton";

function ProfilePageHeader({ creatorAccount, profileCustomizations, isSelfProfile, color, defaultBackground, takingScreenshot }) {
  const seamNavigator = useSeamNavigator();
  const profilePicture = creatorAccount.get("profilePhoto");
  const badges = creatorAccount.get("badges");
  const { account, login } = useSeamUser();

  const renderBadges = () => {
    return badges?.map((badgeEarned, index) => {
      const badgeType = BadgeTypes[badgeEarned.type];
      if (!badgeType) return null; // Skip if the badge type is not found

      return (
        <img
          key={index}
          src={badgeType.imageURL}
          alt={badgeType.displayTitle}
          title={badgeType.displayTitle} 
          className="w-[20px] h-[20px]" 
        />
      );
    });
  };

  const handleBadgeClick = () => {
    if (!account) {
      login();
    } else {
      seamNavigator.navigateTo("/awards/" + creatorAccount.get("profileId"))
    }
  }

  const handleFollowedClick = () => {
    if (!account) {
      login();
    } else {
      seamNavigator.navigateTo('/followed/' + creatorAccount.get("profileId"), creatorAccount)
    }
  }

  const handleFollowersClick = () => {
    if (!account) {
      login();
    } else {
      seamNavigator.navigateTo('/followers/' + creatorAccount.get("profileId"), creatorAccount)
    }
  }

  const StatsSubSection = ({count, label, onClick}) => {
    return (
      <div className="flex flex-row w-full h-auto items-center justify-center space-x-1" onClick={onClick}>
        <h2 className={`mt-0 mb-[2px] text-center text-${color}`}>
          {count}
        </h2>
        <h3 className={`my-0 text-center text-${color} text-opacity-50`}>{label}</h3>
      </div>
    )
  }

  return (
    <div className={`flex flex-col items-center px-4 justify-start w-full h-auto text-${color}`}>
      {/* Avatar and username */}
      <Avatar
        src={profilePicture}
        alt="Profile"
        style={{height: '140px', width: '140px'}}
        className={`rounded-full`}
      />
      <h1 className={`text-${color} w-full h-auto text-center truncate mt-6 mb-0`}>
        {creatorAccount.get("profileId")}
      </h1>
      <h3 className={`text-${color} text-opacity-50 w-full h-auto text-center truncate mt-4 mb-0`}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam nihil pariatur tenetur aliquam ad veniam. Nisi culpa nulla cupiditate vel.
      </h3>
      {/* Stats Section */}
      <div className="flex flex-row items-center justify-center mt-6 space-x-[21px]">
        <StatsSubSection count={creatorAccount.get("numFollowed") ?? 0} label={"Following"} onClick={handleFollowedClick}/>
        <StatsSubSection count={creatorAccount.get("numFollowers") ?? 0} label={"Followers"} onClick={handleFollowersClick}/>
        {/* TODO: Add total numPosts to account object and display here */}
      </div>
      {/* Badge Bar */}
      {badges && badges.length > 0 && (
      <div onClick={handleBadgeClick} className="flex flex-row w-auto h-auto mt-6 space-x-2 items-center justify-center">
        {renderBadges()}
      </div>
      )}
      <div className={`w-full ${!isSelfProfile && 'mt-6'}`}>
        {!isSelfProfile && !takingScreenshot && <ProfilePageFollowButton creatorAccount={creatorAccount} color={color} defaultBackground={defaultBackground}/>}
      </div>
    </div>
  );
}

export default ProfilePageHeader;