import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { useParams } from 'react-router';
import UserProfilePage from './Profile/UserProfilePage';

interface ProfileMetaSEOImageProps {
  profileId?: string;
}

// a standalone route so that it can be screenshot by puppeteer on the backend to provide an image for SEO and metadata
const ProfileMetaSEOImage = (props: ProfileMetaSEOImageProps) => {
  const [profile, setProfile] = useState<Parse.Object | undefined>(undefined);
  const { profileId } = useParams<{ profileId: string }>();

  useEffect(() => {
    const fetchProfile = async () => {
      if (!profileId) {
        return;
      }
      const userQuery = new Parse.Query("Account");
      userQuery.equalTo("profileId", profileId);
      const user = await userQuery.first();
      setProfile(user);
    };

    fetchProfile();
  }, [profileId]);

  return (
    <>
      {profile && <div data-testid="seam-profile-item" className="w-auto h-full overflow-visible rounded-[20px] border border-gray-200 bg-[#FCFCFC] shadow-none">
        <UserProfilePage username={profileId} takingScreenshot={true} />
      </div>}
    </>
  );
};

export default ProfileMetaSEOImage;