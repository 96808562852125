import { FeedItemTagPill } from './FeedItemTagPill';

export const FeedItemTags = ({ hasSpoilers, tags, color }) => {
  const FeedItemSpoilerTagPill = (tag) => {
    return (
      <FeedItemTagPill
        key={tag}
        tagName={tag}
        textColor={'white'}
        backgroundGradient={'linear-gradient(167deg, #FB3939 -1.46%, #EE39FB 108.77%)'}
      />
    );
  };

  return (
    <div className="w-full flex items-center mb-4 justify-between space-x-2 overflow-x-auto hide-scrollbar">
      <div className="flex space-x-2">
        {tags.map((tag, index) => (
          <FeedItemTagPill
            key={index}
            tagName={tag}
            backgroundColor={color}
            textColor={color === 'white' ? 'black' : 'white'}
            backgroundGradient={undefined}
          />
        ))}
      </div>
      {hasSpoilers && (
        <div className="flex-shrink-0">
          {FeedItemSpoilerTagPill("SPOILERS")}
        </div>
      )}
    </div>
  );
};