import React, { useState, useEffect, memo, useRef } from 'react';
import Parse from 'parse';
import { IonPage, IonContent, IonModal, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import Header from '../Navigation/Header';
import { ChevronLeft, LibraryAddOutlined, PaletteOutlined, Search } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import ProfilePageHeader from './ProfilePageHeader';
import { useSeamUser } from '../utils/SeamUserContext';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import { useHistory } from 'react-router';
import ProfileSettingsModal from './ProfileSettingsModal';
import "./Profile.css"
import DesktopSidebarWrapper from '../components/DesktopSidebarWrapper';
import { useMobile } from '../utils/MobileContext';
import WelcomeFromWebCreatedAccountPage from '../components/WelcomeFromWebCreatedAccountPage';
import SeamPillButton from '../Block-SDK/src/components/SeamPillButton';
import { Seo } from '../utils/Seo';
import ClaimGate from '../components/ClaimGate';
import { ReactComponent as StitchIcon } from "../assets/icons/stitchIcon.svg";
import ProfileFeed from './ProfileFeed';

const UserProfilePage = ({ username, ownProfile = false, takingScreenshot = false }) => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const { contextObject, navigateTo } = useSeamNavigator();
  const [creatorAccount, setCreatorAccount] = useState(contextObject?.get("profileId") == username ? contextObject : undefined);
  const [showSettings, setShowSettings] = useState(false);
  const [profileCustomizations, setProfileCustomizations] = useState(undefined);
  const [isSelfProfile, setIsSelfProfile] = useState(ownProfile || username === account?.get("profileId"));
  const [userExists, setUserExists] = useState(null);
  const virtuosoRef = useRef(null);

  useEffect(() => {
    const fetchCreatorAccount = async () => {
      const userQuery = new Parse.Query("Account");
      userQuery.equalTo("profileId", username);
      const user = await userQuery.first();
      setCreatorAccount(user);
      setUserExists(!!user); // Sets true if user exists, otherwise false
    };

    // Fetch account if username doesn't match existing profile
    if (username && username !== creatorAccount?.get("profileId")) {
      fetchCreatorAccount();
    }
    setIsSelfProfile(username === account?.get("profileId"));
  }, [username, account]);

  useEffect(() => {
    const fetchCreatorCustomizations = async () => {
      const profileCustomizationsQuery = new Parse.Query("ProfileCustomizations");
      profileCustomizationsQuery.equalTo("account", creatorAccount);
      const profileCustomizations = await profileCustomizationsQuery.first();
      setProfileCustomizations(profileCustomizations || null);
    }
    fetchCreatorCustomizations();
  }, [creatorAccount]);

  const scrollToTop = () => {
    virtuosoRef.current?.scrollToTop();
  };

  if (userExists === null) {
    return null;
  }

  // for desktop onboarding
  if (account && account.get("isOnboarded") !== true) {
    return <WelcomeFromWebCreatedAccountPage />
  }

  const backgroundImage = profileCustomizations?.get("backgroundImage");
  const color1 = profileCustomizations?.get("color1") || "white";
  const color2 = profileCustomizations?.get("color2") || "white";
  const fontColor = profileCustomizations?.get("fontColor");

  const darkMode = fontColor === "white";
  const color = darkMode ? "white" : "black";

  const getBackgroundStyle = () => {
    // if theres an image, use that
    if (backgroundImage) {
      return { backgroundImage: `url(${backgroundImage})`, backgroundColor: "white" };
    }
    // otherwise, check to see if a custom color gradient has been set (color1 and color2 arent white)
    if (color1 !== "white" || color2 !== "white") {
      return { backgroundImage: `linear-gradient(to bottom, ${color1}, ${color2})` };
    }
    // otherwise, just use the first color (white by default)
    return { backgroundColor: color1 };
  };

  const backgroundStyle = getBackgroundStyle();
  const defaultBackground = !backgroundImage && color1 === "white" && color2 === "white";

  const headerButtonClassName = (color, defaultBackground) => {
    const bgClass = defaultBackground
      ? 'bg-white'
      : `bg-${color} bg-opacity-20 ${color === 'white' ? 'border-neutral-50/50' : 'border-neutral-950/50'
      }`;

    return `border flex items-center justify-center rounded-[24px] ${bgClass}`.trim();
  };

  const headerTextClassName = (defaultBackground, color) =>
    defaultBackground ? 'text-black' : `text-${color}`;

  const headerLeftIcon = () => {
    if (takingScreenshot) return null;

    if (isSelfProfile) {
      return (
        <div
          onClick={() => setShowSettings(true)}
          className={`${headerButtonClassName(color, defaultBackground)} ${!isMobile && 'ml-[48px]'} space-x-2 p-2`}
        >
          <PaletteOutlined
            style={{ fontSize: '16px' }}
            className={headerTextClassName(defaultBackground, color)}
          />
          <span
            className={`${headerTextClassName(defaultBackground, color)} uppercase text-[8px] text-sans font-bold tracking-[1px]`}
          >
            Customize
          </span>
        </div>
      );
    }

    return (
      <div className={!isMobile ? 'ml-[48px]' : ''}>
        <SeamPillButton
          icon={<ChevronLeft style={{ fontSize: '16px' }} className={`text-${color}`} />}
          label={null}
          darkMode={darkMode}
          onClick={() => history.go(-1)}
          isOnChannelPage={true}
        />
      </div>
    );
  };

  const headerRightIcon = () => {
    if (takingScreenshot) return null;

    return (
      <div
        onClick={() => { }}
        className={`${headerButtonClassName(color, defaultBackground)} ${!isMobile && 'mr-[48px]'} w-[38px] h-[38px] p-2`}
      >
        <StitchIcon style={{ fontSize: '16px' }} className={`text-${color}`} />
      </div>
    );
  };

  const seoTitle = `${creatorAccount?.get("profileId")}'s profile`;
  const seoDescription = `Join ${creatorAccount?.get("profileId")} on Tome today!`;
  const seoImage = `https://storage.googleapis.com/tome-posts/profiles/${creatorAccount?.get("profileId")}.jpg`;

  if (!userExists) {
    return <ClaimGate />;
  }

  return (
    <IonPage className="profilePage w-full h-full" style={{ marginTop: 'calc(-1 * env(safe-area-inset-top))', height: 'calc(100vh + env(safe-area-inset-top)' }}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        author={"@" + creatorAccount?.get("profileId")}
        imageURL={seoImage}
        url={"https://www.tomebooks.com/user/" + creatorAccount?.get("profileId")}
      />
      <Header
        leftIcon={isMobile ? headerLeftIcon() : null}
        rightIcon={isMobile ? headerRightIcon() : null}
        leftAction={() => { }}
        scrollToTop={scrollToTop}
        centerIcon={<></>}
        includePadding={true}
        darkMode={darkMode}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <div className="absolute top-0 w-full h-full"
          style={{
            ...backgroundStyle,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: 'calc(100vh + env(safe-area-inset-top))',
            marginTop: 'calc(-1 * env(safe-area-inset-top))',
            zIndex: 0
          }}
        />
        <DesktopSidebarWrapper
          showDesktopHeader={true}
          leftIcon={headerLeftIcon()}
          rightIcon={headerRightIcon()}
          navSection={"profile"}
          darkMode={darkMode}
        >
          <ProfileFeed
            creatorAccount={creatorAccount}
            profileCustomizations={profileCustomizations}
            isSelfProfile={isSelfProfile} 
            color={color}
            defaultBackground={defaultBackground}
            takingScreenshot={takingScreenshot} />
          <IonModal
            isOpen={showSettings}
            onDidDismiss={() => setShowSettings(false)}
            swipeToClose={true}
            canDismiss={true}
            className={`h-full`}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
          >
            <ProfileSettingsModal
              profileCustomizations={profileCustomizations}
              updateProfile={(updated) => { setProfileCustomizations(updated) }}
              close={() => setShowSettings(false)} />
          </IonModal>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default memo(UserProfilePage);