import Parse from "parse";
import { useEffect, useState } from "react";
import { Dialog, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../components/LoadingComponent";
import mixpanel from "mixpanel-browser";
import MobileOnboardingTutorial from "./MobileOnboardingTutorial";
import MobileLoginComponent from "./MobileLoginComponent";
import { useSeamUser } from "../utils/SeamUserContext";
import { IonContent, IonPage } from "@ionic/react";
import { motion } from "framer-motion";

export default function MobileLandingPage() {
  const { account, isLoadingAccount, isNewUser } = useSeamUser();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_IS_LOADING", (loading) => {
      setIsLoading(loading);
    });

    mixpanel.track("iOS Landing Page Viewed");
  }, []);

  useEffect(() => {
    if (account && Parse.User.current()) {
      history.replace("/homeTab");
    }
  }, [account]);

  if (isNewUser) {
    return (
      <MobileOnboardingTutorial />
    );
  }

  return (
    <IonPage>
      <IonContent fullscreen={true} scrollY={false}>
        <div
          className="flex flex-col h-full w-full justify-between items-center"
          style={{
            background: "white",
          }}
        >
          <div className="relative flex flex-col h-full w-full items-center justify-between overflow-hidden">
            <h1>TOME</h1>

            <div className={`flex-1 w-full flex justify-center items-center my-6`}>
              <div className="font [Public Sans] text-white text-[24px] text-center"> TOME </div>
            </div>
          </div>
          {/* Mobile Login Component */}
          <div className="mb-[20px] w-full self-center">
            <MobileLoginComponent />
          </div>
        </div>

        <Dialog open={isLoading} onClose={() => setIsLoading(false)}>
          <LoadingComponent />
        </Dialog>
      </IonContent>
    </IonPage>
  );
}