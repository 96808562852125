import { MemeGenFeedComponent, MemeGenComposerComponent } from './MemeGenApp'
import { QuickPollFeedComponent, QuickPollComposerComponent } from './QuickPollApp'
import { BookshelfFeedComponent, BookshelfComposerComponent } from './BookshelfApp'
import { UnknownFeedComponent, UnknownComposerComponent } from './UnknownApp'
import { MarqueeFeedComponent, MarqueeComposerComponent } from './MarqueeBlock'
import { IFrameFeedComponent, IFrameComposerComponent } from './IFrameBlock'
import { TextEditFeedComponent, TextEditComposerComponent } from './TextEditBlock'
import { ImageFeedComponent, ImageComposerComponent } from './ImageBlock'
import { GiphyFeedComponent, GiphyComposerComponent } from './GiphyBlock'
import { FlashingTextFeedComponent, FlashingTextComposerComponent } from './FlashingTextBlock'
import { LinkBookmarkFeedComponent, LinkBookmarkComposerComponent } from './LinkBookmarkBlock'
import { VideoFeedComponent, VideoComposerComponent } from './VideoBlock'
import { BlockModel, BlockTypes, ComposerComponentProps } from './types'
import { ReviewThingsAppComposerComponent, ReviewThingsAppFeedComponent } from './ReviewThingsApp'

export default class BlockFactory {
  static getFeedComponent(model: BlockModel, update: ((data: { [key: string]: string; }) => void)) {
    switch (model.type) {
      case "iframe": return <IFrameFeedComponent model={model} update={update}/>;
      case "image": return <ImageFeedComponent model={model} update={update}/>;
      case "text": return <TextEditFeedComponent model={model} update={update}/>;
      case "link": return <LinkBookmarkFeedComponent model={model} update={update}/>;
      case "video": return <VideoFeedComponent model={model} update={update}/>;
      case "giphy": return <GiphyFeedComponent model={model} update={update}/>;
      case "Marquee": return <MarqueeFeedComponent model={model} update={update}/>;
      case "FlashingText": return <FlashingTextFeedComponent model={model} update={update}/>;
      case "Bookshelf": return <BookshelfFeedComponent model={model} update={update}/>;
      case "QuickPoll": return <QuickPollFeedComponent model={model} update={update}/>;
      case "MemeGen": return <MemeGenFeedComponent model={model} update={update} />;
      case "ReviewThings": return <ReviewThingsAppFeedComponent model={model} update={update} />;
      // new feed components go here
      default: return UnknownFeedComponent({ model, update });
    }
  }

  static getComposerComponent(props: ComposerComponentProps) {
    const { model } = props;
    switch (model.type) {
      case "iframe": return IFrameComposerComponent(props);
      case "image": return ImageComposerComponent(props);
      case "text": return TextEditComposerComponent(props);
      case "link": return LinkBookmarkComposerComponent(props);
      case "video": return VideoComposerComponent(props);
      case "giphy": return GiphyComposerComponent(props);
      case "Marquee": return MarqueeComposerComponent(props);
      case "FlashingText": return FlashingTextComposerComponent(props);
      case "Bookshelf": return BookshelfComposerComponent(props);
      case "QuickPoll": return QuickPollComposerComponent(props);
      case "MemeGen": return MemeGenComposerComponent(props);
      case "ReviewThings": return ReviewThingsAppComposerComponent(props);
      // new composer components go here
      default: return UnknownComposerComponent(props);
    }
  }

  static getPrintableBlockName(model: BlockModel): string {
    let type = model?.type
    let blockType = BlockTypes[type]
    return blockType != undefined ? BlockTypes[type].displayName : "Unknown"
  }

  static doesBlockPost(model: BlockModel): boolean {
    let type = model.type
    return BlockTypes[type].doesBlockPost
  }

  static doesBlockEditFullscreen(type: string): boolean {
    return BlockTypes[type]?.fullscreenEdit ?? false
  }
}
