import { IonHeader } from "@ionic/react";

export default function TomeHeaderBar({
  leftComponent,
  rightComponent,
  centerComponent,
}) {
  return (
    <IonHeader className="ion-no-border">
      <div className="flex items-center justify-between bg-transparent p-4">
        {leftComponent ? leftComponent : <></>}
        <div className="absolute left-[50%] translate-x-[-50%] cursor-pointer">
          {centerComponent}
        </div>
        {rightComponent ? rightComponent : <></>}
      </div>
    </IonHeader>
  );
}
