import { memo, useEffect, useMemo, useRef, useState } from "react";
import Parse from 'parse';
import SeamEngagementBar from "../Comment/EngagementBar/SeamEngagementBar";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { Avatar, Divider, Typography } from "@mui/material";
import { formatPostDate } from "../utils/formatPostDate";
import UiKitLinkify from "../Comment/Linkify";
import FeedErrorBoundary from "../components/FeedErrorBoundary";
import BlockFactory from "../Block-SDK/src/blocks/BlockFactory";
import FeedItemOptions from "./FeedItemOptions";
import { FeedItemTags } from "./FeedItemTags";
import { PostType } from "../Post/TomePostRepository";
import UsernameWithAvatar from "../components/UsernameWithAvatar";
import { TomeButton } from "../components/TomeButton";

function FeedItem(props) {
  const { post, isOnPostDetailPage = false, isOnChannelPage, color, defaultBackground, width } = props;
  const [showContent, setShowContent] = useState(false);
  const seamNavigator = useSeamNavigator();
  const postAuthor = post?.get("author");
  const parentPost = post?.get("parentPost");
  const postData = parentPost ? parentPost.get("postData") : post.get("postData");
  const blockData = postData.blockData;
  const collection = post?.get("collection");
  const parentPostAuthor = parentPost?.get('author');
  const parentPostData = parentPost?.get('postData');

  const update = (data) => {
    Parse.Cloud.run("updatePostModel", { postId: post.id, updatedData: data })
  }

  const FeedItemActionPill = ({ postType, color }) => {
    const isPost = postType === PostType.post;
    const actionString = isPost ? 'POSTED TO' : 'COLLECTED TO';

    const backgroundClass = isPost ? 'bg-[#D8E7DD]' : 'bg-[#EFE3D6]';
    const textColorClass = color === 'black'
      ? 'text-black/50'
      : isPost
        ? 'text-[#E7EEE9]'
        : 'text-[#EFE3D6]';

    return (
      <h4
        className={` 
          ${backgroundClass} 
          ${textColorClass} 
          bg-opacity-50 text-sans w-auto whitespace-nowrap font-bold px-2 py-1 my-0
        `}
      >
        {actionString}
      </h4>
    );
  };

  const FeedItemHeader = () => {
    return (
      <div className="h-[24px] w-full flex flex-row items-center justify-between">
        <div className="max-h-[24px] w-auto flex flex-row items-center justify-start space-x-2 truncate">
          <UsernameWithAvatar account={postAuthor} color={color} avatarSize={16} />
          <FeedItemActionPill postType={post.get("dataType")} color={color} />
          {collection &&
            <h2
              onClick={() => seamNavigator.navigateTo(`/collection/${collection.id}`)}
              className={`text-${color} text-center overflow-visible truncate my-0`}
            >
              {collection?.get('name')}
            </h2>
          }
        </div>
        <FeedItemOptions post={post} ownerAccount={postAuthor} isOnChannelPage={isOnChannelPage} color={color} />
      </div>
    )
  }

  const spoilerClass = post.get("hasSpoilers") && !showContent ? "blur-lg" : "";

  const AttachmentComponent = useMemo(() => {
    if (!blockData) return null;
    let height = "100%"
    if (post.get("aspectRatio") && width > 0) {
      const postHeight = width / post.get("aspectRatio");
      height = postHeight;
    }

    return (
      <div className="flex items-center justify-center w-full relative">
        {post.get("hasSpoilers") && !showContent && (
          <div className="absolute inset-0 z-20 flex items-center bg-black/50 bg-blend-color-dodge rounded-2 mb-4 justify-center">
            <TomeButton
              containerClass="text-black rounded-[64px]"
              onClick={() => setShowContent(true)}
              label={<h4 className="my-0 uppercase">Reveal #Spoilers</h4>}
              gradient={undefined}
            />
          </div>
        )}
        <div className={`${spoilerClass} flex items-center justify-center w-full mb-4 rounded-[16px] overflow-clip`} style={{ height: height }}>
          <FeedErrorBoundary>
            {BlockFactory.getFeedComponent(blockData, update)}
          </FeedErrorBoundary>
        </div>
      </div>
    )
  }, [post.id, width, showContent])

  const FeedItemTimeSincePosted = ({ topOffset }) => {
    return (
      <span
        className={`text-${color} text-[10px] font-bold uppercase tracking-[1px] text-opacity-[50%] ml-2 relative`}
        style={{ top: `-${topOffset}px` }}
      >
        ·&nbsp;&nbsp;{formatPostDate(post.createdAt)}
      </span>
    );
  };

  const FeedItemContent = useMemo(() => {
    return (
      <div className={`flex flex-col items-center justify-start w-full h-auto mt-1`}>
        {post?.get("dataType") == PostType.post ? (
          <div className={`ml-1 mb-4 flex flex-row items-center justify-start w-full h-auto`}>
            <h3 className={`text-${color} text-base text-start w-full h-auto leading-[24px] my-0`}>
              <UiKitLinkify>
                {postData.text}
              </UiKitLinkify>
              <FeedItemTimeSincePosted topOffset={'1'} />
            </h3>
          </div>
        ) : (
          <div className={`flex flex-row mt-1 w-full mb-4`}>
            {/* left aligned gray vertical bar on collected posts */}
            <div className={`mx-4 my-1 w-[2px] h-auto bg-${color} bg-opacity-10`}></div>
            <div className="h-full w-full flex items-start">
              <div className="text-black h-full text-start items-start justify-end font-normal w-full">
                <span className="inline-flex items-center h-full">
                  <Avatar
                    src={getProcessedProfilePictureURL(parentPostAuthor?.get('profilePhoto'))}
                    className={`mr-2 h-full`}
                    style={{ height: '16px', width: '16px' }}
                  />
                  <h2
                    onClick={() => seamNavigator.navigateTo(`/user/${parentPostAuthor?.get("profileId")}`)}
                    className={`my-0 cursor-pointer text-${color} ${parentPostData.text && 'mr-2'}`}
                  >
                    {parentPostAuthor.get('profileId')}
                  </h2>
                </span>
                <span
                  className={`text-${color} relative text-[14px] font-normal font-sans leading-[24px]`}
                  style={{ top: '-1px' }}
                >
                  {parentPostData.text && parentPostData.text}
                </span>
                <FeedItemTimeSincePosted topOffset={'3'} />
              </div>
            </div>
          </div>
        )}
        {AttachmentComponent}
      </div>
    )
  }, [post, showContent])

  return (
    <div
      key={post?.id}
      className="flex flex-col w-auto mx-4 h-full items-start justify-start bg-transparent shadow-none"
    >
      <FeedItemHeader />
      {FeedItemContent}
      {post.get("tags")?.length > 0 || post.get("hasSpoilers") && (
        <FeedItemTags hasSpoilers={post.get("hasSpoilers")} tags={post.get("tags")} seamNavigator={seamNavigator} color={color} />
      )}
      <SeamEngagementBar post={post} collection={collection} isOnPostDetailPage={isOnPostDetailPage} isOnChannelPage={isOnChannelPage} color={color} defaultBackground={defaultBackground} />
    </div>
  );
}

export default memo(FeedItem);
