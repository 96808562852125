import quoteIcon from "./blockIcons/QuotesIcon.png";
import cameraIcon from "./blockIcons/CameraIcon.png";
import videoIcon from "./blockIcons/Video.png";
import imageIcon from "./blockIcons/MediaIcon.png";
import bookmarkIcon from "./blockIcons/Link.png";
import giphyIcon from "./blockIcons/GIFIcon.png";
import marqueeIcon from "./blockIcons/marqueeIcon.png"
import flashingTextIcon from "./blockIcons/flashingTextIcon.png";
import bookIcon from './blockIcons/bookIcon.png'
import pollIcon from "./blockIcons/PollIcon.png";
import ReviewThingsIcon from "./blockIcons/ReviewThingsIcon.png";
import SeamMemeIcon from "./blockIcons/SeamMemeIcon.png";

export type BlockModel = {
  type: string;
  data: { [key: string]: string };
  uuid: string; // must be unique to avoid layout issues
};

export interface AccountModel {
  readonly spotifyAccount?: any;
}

export interface Badge {
  type: string; // Badge name
}

export interface FeedComponentProps {
  model: BlockModel;
  update: (data: { [key: string]: string }) => void;
  width?: number;
}

export interface ComposerComponentProps {
  done: (data: BlockModel) => void;
  model: BlockModel;
  width?: number;
}

export type PointSplit = {
  username: string;
  split: number; // 1.0 is 100%, 0.5 is 50%, etc. Make sure to add up to 1.0!
};

export type BlockType = {
  type: string;
  displayName: string; // in add block menu
  displayDescription: string; // in add block menu
  icon: string; // in add block menu
  deprecated: boolean; // if users can continue to add the block
  doesBlockPost: boolean; // do updates of this block show up in the feed?
  doesBlockEdit: boolean; // does the user need to edit this block?
  createdBy: PointSplit[]; // the users who created the block
  fullscreenEdit: boolean; // does the block have a fullscreen edit modal?
};

// In order of how they should show up in the drop-down
export const BlockTypes: { [key: string]: BlockType } = {
  "Quote": {
    type: "Quote",
    displayName: "Quote",
    displayDescription: "Rich text formatting!",
    icon: quoteIcon,
    deprecated: false,
    doesBlockPost: true,
    doesBlockEdit: true,
    createdBy: [{username: "nick", split: 0.50}, {username: "rocco", split: 0.50}],
    fullscreenEdit: true,
  },
  "Bookshelf": {
    type: "Bookshelf",
    displayName: "Book",
    displayDescription: "Post a book you've read",
    icon: bookIcon,
    deprecated: false,
    doesBlockPost: true,
    doesBlockEdit: true,
    createdBy: [{username: "nick", split: 0.50}, {username: "rocco", split: 0.50}],
    fullscreenEdit: true,
  },
  "QuickPoll": { 
    type: "QuickPoll",
    displayName: "Poll",
    displayDescription: "A tool for creating and sharing quick polls on any topic",
    icon: pollIcon,
    deprecated: false,
    doesBlockPost: true,
    doesBlockEdit: true,
    createdBy: [{username: "bkaraba", split: 1}], 
    fullscreenEdit: false,
  },
  "Camera": { 
    type: "Camera",
    displayName: "Camera",
    displayDescription: "Use your device's camera to post some media",
    icon: cameraIcon,
    deprecated: false,
    doesBlockPost: true,
    doesBlockEdit: true,
    createdBy: [{username: "nick", split: 0.50}, {username: "rocco", split: 0.50}],
    fullscreenEdit: false,
  },
  image: {
    type: "image",
    displayName: "Image ",
    displayDescription: "Add an image using a URL.",
    icon: imageIcon,
    deprecated: false,
    doesBlockPost: true,
    doesBlockEdit: true,
    createdBy: [{username: "seam", split: 1}],
    fullscreenEdit: false,
  },
  giphy: {
    type: "giphy",
    displayName: "GIPHY ",
    displayDescription: "Choose a gif.",
    icon: giphyIcon,
    deprecated: false,
    doesBlockPost: true,
    doesBlockEdit: true,
    createdBy: [{username: "seam", split: 1}],
    fullscreenEdit: false,
  },
  video: {
    type: "video",
    displayName: "Video",
    displayDescription: "Add a video from Youtube, Loom, or Vimeo using a URL.",
    icon: videoIcon,
    deprecated: true,
    doesBlockPost: false,
    doesBlockEdit: true,
    createdBy: [{username: "seam", split: 1}],
    fullscreenEdit: false,
  },
  "Marquee": {
    type: "Marquee",
    displayName: "Marquee",
    displayDescription: "Displays a scrolling banner of text",
    icon: marqueeIcon,
    deprecated: true,
    doesBlockPost: false,
    doesBlockEdit: true,
    createdBy: [{username: "nick", split: 1}],
    fullscreenEdit: false,
  },
  "FlashingText": {
    type: "FlashingText",
    displayName: "Flashing Text",
    displayDescription: "Text and background swap colors, creating a flashing effect.",
    icon: flashingTextIcon,
    deprecated: true,
    doesBlockPost: false,
    doesBlockEdit: true,
    createdBy: [{username: "ttran010", split: 1}],
    fullscreenEdit: false,
  },
  "ReviewThings": {
    type: "ReviewThings",
    displayName: "Review Things",
    displayDescription: "Review things with other things on a five point scale",
    icon: ReviewThingsIcon,
    deprecated: true,
    doesBlockPost: false,
    doesBlockEdit: true,
    createdBy: [{username: "jakeaicher", split: 1}],
    fullscreenEdit: false,
  },
  "MemeGen": { 
    type: "MemeGen",
    displayName: "Seam Meme",
    displayDescription: "Meme creator that allows users to import popular memes and edit them.",
    icon: SeamMemeIcon,
    deprecated: true,
    doesBlockPost: false,
    doesBlockEdit: true,
    createdBy: [
      {username: "danielgavidia", split: 1.0 / 7},
      { username: "irtizaaftabmian", split: 1.0 / 7 },
      { username: "akshayb03", split: 1.0 / 7 },
      { username: "embo", split: 1.0 / 7 },
      { username: "emmabrooke1122", split: 1.0 / 7 },
      { username: "palupadhyaya", split: 1.0 / 7 },
      { username: "julyjuicej", split: 1.0 / 7 }
    ],
    fullscreenEdit: false,
  },
  link: {
    type: "link",
    displayName: "Link ",
    displayDescription: "Add a link using a URL.",
    icon: bookmarkIcon,
    deprecated: true,
    doesBlockPost: false,
    doesBlockEdit: true,
    createdBy: [{username: "seam", split: 1}],
    fullscreenEdit: false,
  },
};
