import React, {
  useReducer,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { LogLevel, Virtuoso } from 'react-virtuoso';
import { Stack, Skeleton, Divider } from "@mui/material";
import { useSeamUser } from "../utils/SeamUserContext";
import { useMobile } from '../utils/MobileContext';
import FeedItem from '../Discover/FeedItem';
import { useScrolling } from '../Navigation/ScrollingContext';
import { IonRefresher, IonRefresherContent } from '@ionic/react';

const POSTS_PER_PAGE = 10;

const initialState = {
  loadedPosts: [],
  hasMore: true,
  isLoading: false,
  refresh: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'REFRESH':
      return { ...state, hasMore: true, refresh: true };
    case 'POSTS_LOADED':
      return {
        ...state,
        loadedPosts: [...state.loadedPosts, ...action.payload],
        isLoading: false,
        hasMore: action.payload.length === POSTS_PER_PAGE,
        refresh: false,
      };
    case 'SET_LOADED_POSTS':
      return { ...state, loadedPosts: action.payload };
    case 'ADD_LOADED_POST':
      return { ...state, loadedPosts: [action.payload, ...state.loadedPosts] };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'DELETE_POST':
      const postId = action.payload;
      return {
        ...state,
        loadedPosts: state.loadedPosts.filter((prevItem) => {
          return prevItem.id !== postId;
        }),
      };
    case 'ADD_POST':
      return {
        ...state,
        loadedPosts: [action.payload, ...state.loadedPosts],
      };
    default:
      throw new Error('Unknown action type');
  }
}

const Feed = forwardRef(
  (
    {
      postQuery,
      Header,
      onPostsLoaded,
      dependencies = [],
      isOnChannelPage,
      color,
      defaultBackground,
      ...props
    },
    ref
  ) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const virtuosoRef = useRef(null);
    const widthRef = useRef(null);
    const { isScrolling, setIsScrolling } = useScrolling();
    const { account, refreshAccount } = useSeamUser();

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        if (virtuosoRef.current) {
          virtuosoRef.current.scrollToIndex({ index: 0, behavior: 'smooth' });
        }
      },
      addPost: (post) => {
        dispatch({ type: 'ADD_POST', payload: post });
      },
      reload: () => {
        dispatch({ type: 'REFRESH' });
      },
    }));

    useEffect(() => {
      if (!state.isLoading && (state.loadedPosts.length === 0 || state.refresh)) {
        loadMore();
      }
    }, [state.refresh]);

    useEffect(() => {
      if (dependencies.length > 0) {
        dispatch({ type: 'REFRESH' });
      }
    }, dependencies); // needed to refresh home feed if you unfollow/follow a channel / block someone etc.

    useEffect(() => {
      if (onPostsLoaded) {
        onPostsLoaded(state.loadedPosts);
      }
    }, [state.loadedPosts, onPostsLoaded]);

    useEffect(() => {
      const handleDelete = (postId) => {
        dispatch({ type: 'DELETE_POST', payload: postId });
      };

      window.emitter.on('SEAM_EVENT_DELETE_POST', handleDelete);
      return () => {
        window.emitter.off('SEAM_EVENT_DELETE_POST', handleDelete);
      };
    }, []);

    // Handle post addition
    useEffect(() => {
      const handleAdd = (newItem) => {
        if (!newItem) return;
        dispatch({ type: 'ADD_POST', payload: newItem });
      };

      window.emitter.on('SEAM_EVENT_POST_SUCCESSFUL', handleAdd);
      return () => {
        window.emitter.off('SEAM_EVENT_POST_SUCCESSFUL', handleAdd);
      };
    }, []);

    async function loadMore() {
      if (!account) return;
      if (state.isLoading || !state.hasMore) return;
      dispatch({ type: 'SET_IS_LOADING', payload: true });
      let query = postQuery;
      query.include("author");
      query.include("collection")
      // Global query paramenters that should always be respected across all feeds in the app
      // ex, we never want to show deleted posts, or posts from blocked users
      query.equalTo('isDeleted', false);
      query.equalTo("isHidden", false);
      const blockedTags = account.get("blockedTags") || [];
      if (blockedTags.length > 0) {
        query.notContainedIn("tags", blockedTags);
      }
      const blockedUsers = account.get("blockedUsers") || [];
      if (blockedUsers.length > 0) {
        query.notContainedIn("author", blockedUsers);
      }

      // for pagination
      query.limit(POSTS_PER_PAGE);
      query.skip(state.loadedPosts.length);

      const posts = await query.find();

      dispatch({ type: 'POSTS_LOADED', payload: posts });
    }

    function handleRefresh(event) {
      return new Promise((resolve) => {
        if (refreshAccount) refreshAccount();
        dispatch({ type: 'SET_LOADED_POSTS', payload: [] });

        setTimeout(() => {
          loadMore();
          event.detail.complete();
          resolve();
        }, 100);
      });
    }   

    const FooterComponent = ({ context }) => {
      const { isLoading } = context.state;
      const { isMobileApp } = useMobile();
      return isLoading ? (
        <div
          className={`w-auto px-6 h-full my-6 ${!isMobileApp && 'max-w-[720px] flex flex-col justify-center mx-auto'
            }`}
        >
          <Stack spacing={1}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={80} />
          </Stack>
        </div>
      ) : (
        <div className="bg-transparent h-[128px]" />
      );
    };

    return (
      <>
        <IonRefresher slot="fixed" className="ion-refresher-high-z" onIonRefresh={handleRefresh}>
          <IonRefresherContent
            pullingIcon="circles"
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        <Virtuoso
          ref={virtuosoRef}
          className="hide-scrollbar ion-content-scroll-host w-full"
          data={state.loadedPosts}
          endReached={loadMore}
          overscan={10}
          isScrolling={(scrolling) => setIsScrolling(scrolling)}
          itemContent={(index, post) => {
            return (
              <div key={index} ref={widthRef}>
                <FeedItem post={post} isOnChannelPage={isOnChannelPage} color={color} defaultBackground={defaultBackground} width={widthRef.current?.offsetWidth - 32} />
                <div className="pt-4 pb-8 w-full h-auto px-4">
                  <Divider className={`w-full h-[1px] bg-${color} bg-opacity-10`} />
                </div>
              </div>
            )
          }}
          increaseViewportBy={{ top: 800, bottom: 800 }}
          components={{
            Header,
            Footer: FooterComponent,
          }}
          context={{ state, dispatch, ...props }}
        />
      </>
    );
  }
);

export default Feed;