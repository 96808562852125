import React, { memo, useEffect, useState } from 'react';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import QuestManager from '../Quests/QuestManager';
import { BlockTypes } from '../Block-SDK/src/blocks/types';
import { useMobile } from '../utils/MobileContext';
import ComposerMiniAppPhoto from '../Block-SDK/src/Composer/ComposerMiniAppPhoto';
import { QuestTypes } from './QuestTypes';
import NotificationReminderDialog from '../components/NotificationReminderDialog';
import { IonContent, IonHeader, IonModal } from '@ionic/react';
import { Check } from '@mui/icons-material';

class DailyQuestsCache {
  static quests = null;
}

const DailyQuestsBar = ({ account }) => {
  const seamNavigator = useSeamNavigator();
  const [dailyQuests, setDailyQuests] = useState([]);
  const [completedQuests, setCompletedQuests] = useState(new Set());
  const { isMobile } = useMobile();
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [modalState, setModalState] = useState(undefined);

  useEffect(() => {
    const checkAndResetStreaks = () => {
      const quests = account?.get('quests') || {};
      const streak = quests.streak || {};

      const now = new Date();
      const lastPost = streak.lastPost ? new Date(streak.lastPost) : null;
      const lastCollect = streak.lastCollect ? new Date(streak.lastCollect) : null;

      // If either post or collect hasn't been done for more than 48 hours, reset the streak
      const timeDifferenceInHours = (date) => (now - date) / (1000 * 60 * 60); // Convert ms to hours

      if (
        (lastPost && timeDifferenceInHours(lastPost) > 48) ||
        (lastCollect && timeDifferenceInHours(lastCollect) > 48)
      ) {
        QuestManager.resetStreaks(streak);
        quests.streak = streak;
        account.set('quests', quests);
        account.save();
      }
    };
    checkAndResetStreaks();
  }, [account]);

  useEffect(() => {
    const fetchDailyQuests = async () => {
      if (DailyQuestsCache.quests) {
        setDailyQuests(DailyQuestsCache.quests);
      } else {
        const quests = await QuestManager.getDailyQuests();
        DailyQuestsCache.quests = quests;
        setDailyQuests(quests);
      }
    };

    fetchDailyQuests();
  }, []);

  useEffect(() => {
    const fetchCompletedQuests = () => {
      const dailyQuestTags = account?.get("dailyQuests") || [];
      setCompletedQuests(new Set(dailyQuestTags));
    };

    fetchCompletedQuests();
  }, [account]);

  const copyReferralLinkToClipboard = (profileId) => {
    const referralLink = `https://tomebooks.com/?ref=${profileId}`;
    navigator.clipboard.writeText(referralLink).then(() => {
      alert('Referral link copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy referral link:', err);
    });
  };

  const loadingSkeleton = (
    Array(2).fill().map((_, index) => (
      <div
        key={index}
        className="flex flex-row items-start ml-4 p-1 justify-start max-w-[300px] rounded-[24px] bg-seam-black/10 gradient-border cursor-pointer"
      >
        <div className="m-4 flex flex-col w-[300px] h-[140px] justify-between">
          <div className="bg-gray-200 h-20 w-full rounded mb-4 animate-pulse"></div>
          <div className="bg-gray-200 h-4 w-3/4 rounded mb-2 animate-pulse"></div>
          <div className="bg-gray-200 h-4 w-1/2 rounded animate-pulse"></div>
        </div>
      </div>
    ))
  );

  const getStreakEmoji = (dayStreak) => {
    const emojiMap = [
      { days: 1, emoji: "🌱" },
      { days: 3, emoji: "🌼" },
      { days: 5, emoji: "🌈" },
      { days: 7, emoji: "💪" },
      { days: 10, emoji: "🏆" },
      { days: 15, emoji: "🚀" },
      { days: 20, emoji: "🌟" },
      { days: 30, emoji: "🌕" },
      { days: 60, emoji: "💥" },
      { days: 90, emoji: "💎" },
      { days: 120, emoji: "🔥🔥🔥" }
    ];
    const milestone = emojiMap.reverse().find((item) => dayStreak >= item.days);
    return milestone ? milestone.emoji : "🌱";
  };

  const isActionWithin24Hours = (lastAction) => {
    if (!lastAction) return false;
    const lastActionDate = new Date(lastAction);
    const now = new Date();
    const timeDifference = now.getTime() - lastActionDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // 24 hours to ms
  };

  const renderStreakCard = () => {
    const quests = account?.get("quests") || {};
    const streak = quests.streak || {};

    const postWithin24Hours = isActionWithin24Hours(streak.lastPost);
    const collectWithin24Hours = isActionWithin24Hours(streak.lastCollect);

    const dayStreak = streak.postStreak || 1;
    const streakEmoji = getStreakEmoji(dayStreak);
    const isCompleted = postWithin24Hours && collectWithin24Hours;

    return (
      <div className="flex flex-col items-start p-1 w-auto justify-start rounded-[24px] bg-seam-black/10 gradient-border cursor-pointer">
        <div className="m-4 flex w-auto flex-col min-w-[300px] max-w-[300px] justify-between space-y-2">
          <div className="flex flex-row items-center">
            <span className="mr-2">{streakEmoji}</span>
            <h3 className="w-auto flex-1">{dayStreak} Day Streak</h3>
            <div className={`flex items-center ${isCompleted ? 'bg-seam-pink rounded-full' : 'bg-seam-green rounded'} text-center px-1`}>
            <span className="flex flex-row items-center justify-center">
              <h4 className={`${isCompleted ? 'text-seam-white' : 'text-seam-black'} text-center`}>
                {isCompleted ? 'Completed' : `+50`}
              </h4>
              &nbsp;
              {!isCompleted && <img className="w-4 h-4" src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png" />}
            </span>
            {isCompleted && <Check className="ml-2 h-4 w-4 text-white" />}
          </div>
          </div>
          <div className="flex flex-row">
            <span className="flex flex-row items-center justify-start w-full">
              <h4 className="text-[#A2A1A1]">Posts Created:&nbsp;</h4>
              <h4 className="text-seam-black truncate-2-lines">{postWithin24Hours ? '1/1' : '0/1'}</h4>
            </span>
            <span className="flex flex-row items-center justify-start w-full">
              <h4 className="text-[#A2A1A1]">Posts Collected:&nbsp;</h4>
              <h4 className="text-seam-black truncate-2-lines">{collectWithin24Hours ? '1/1' : '0/1'}</h4>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const tapQuest = (quest) => {
    const profileId = account.get('profileId');
    switch (quest.name) {
      case 'connections':
        setModalState('connections');
        break;
      case 'notifications':
        setOpenNotificationDialog(true);
        break;
      case 'referrals':
        setModalState('referrals');
        break;
      case 'collections':
        seamNavigator.navigateTo(`/followed/${profileId}`);
        break;
      default:
        console.log(`No action defined for quest: ${quest.name}`);
    }
  };

  const isQuestCompleted = (questTag, questName) => {
    if (completedQuests.has(questTag)) {
      return true;
    }

    const staticQuests = account?.get("quests") || {};
    const questProgress = staticQuests[questName];
    if (questProgress && questProgress.completed >= questProgress.total) {
      return true;
    }

    return false;
  };

  const modalContentForState = () => {
    switch (modalState) {
      case 'connections':
      case 'referrals':
      default: 
        return null;
    }
  }

  const renderQuestCard = (quest, index, isCompleted, blockType, reward, isStaticQuest = false) => (
    <div
      key={index}
      className="flex flex-col items-start p-1 w-auto justify-start rounded-[24px] bg-seam-black/10 gradient-border cursor-pointer"
      onClick={() => tapQuest(quest)}
    >
      <div className="m-4 flex w-auto flex-col min-w-[300px] max-w-[300px] justify-between space-y-2">
        <div className="flex flex-row items-center justify-start w-auto h-full">
          {!isStaticQuest && <ComposerMiniAppPhoto block={BlockTypes[blockType]} size={24} />}
          <h3 className="align-top w-auto flex-1">{BlockTypes[blockType]?.displayName || quest?.displayTitle}</h3>
          <div className={`flex items-center ${isCompleted ? 'bg-seam-pink rounded-full' : 'bg-seam-green rounded'} text-center px-1`}>
            <span className="flex flex-row items-center justify-center">
              <h4 className={`${isCompleted ? 'text-seam-white' : 'text-seam-black'} text-center`}>
                {isCompleted ? 'Completed' : `+${reward}`}
              </h4>
              &nbsp;
              {!isCompleted && <img className="w-4 h-4" src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png" />}
            </span>
            {isCompleted && <Check className="ml-2" size={16} color="white" />}
          </div>
        </div>
        <div className="flex flex-col items-start h-full justify-between">
          <span className="flex flex-row items-center justify-start w-full">
            <h4 className="text-[#A2A1A1]">{"Challenge:  "}</h4>
            <h4 className="text-seam-black truncate-2-lines">
              {isStaticQuest ? quest.description : quest?.get("description")}
            </h4>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col w-full max-w-[720px] mt-4 self-center h-full">
      <div className="overflow-x-auto">
        <div className={`flex flex-row overflow-x-scroll space-x-4 hide-scrollbar px-4`}>
          {Object.values(QuestTypes)
            .filter((quest) => !isQuestCompleted(null, quest?.name))
            .map((quest, index) => {
              return renderQuestCard(quest, index + dailyQuests.length, false, quest?.name, quest?.value, true);
            })}
          {renderStreakCard()}
        </div>
      </div>
      <NotificationReminderDialog
        open={openNotificationDialog}
        onClose={() => setOpenNotificationDialog(false)}
      />

      <IonModal isOpen={modalState != undefined} onDidDismiss={() => setModalState(undefined)}>
        <IonContent>
          {modalContentForState()}
        </IonContent>
      </IonModal>
    </div>
  );
};

export default memo(DailyQuestsBar);