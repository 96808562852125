import mixpanel from 'mixpanel-browser';
import Parse from 'parse'
import { sendCommentLikeNotification, sendCommentMentionNotification } from '../utils/NotificationSendingHelpers';

export enum PostType {
  blockUpdate,
  friendAccepted,
  wallBlockPost
}

export default class SeamCommentRepository {
  static createComment(commentData: {
    author: Parse.Object, // pointer to the creator of the comment
    parentPost: Parse.Object, // pointer to the post where this comment lives
    parentComment: Parse.Object | undefined, // pointer for nested comments
    text: string,
    miniappData: any,
    mentionedUsers: any[],
  },
    onSuccess: (newComment: Parse.Object) => void, onError: (message: any) => void) {

    const Comment = Parse.Object.extend("Comment");
    const comment = new Comment();

    comment.save({
      author: commentData.author,
      parentPost: commentData.parentPost,
      parentComment: commentData.parentComment,
      text: commentData.text,
      miniappData: commentData.miniappData,
      commentCount: 0,
      reactionCount: 0,
      reported: [], // array of userIDs that have reported the post
      isDeleted: false
    }).then(async (newComment: Parse.Object) => {
      commentData.parentPost.increment("commentCount")
      commentData.parentPost.save()

      for (const mention of commentData.mentionedUsers) {
        const mentionee = mention.userId;
        sendCommentMentionNotification(commentData.author, mentionee, commentData.parentPost.id);
      }

      if (commentData.parentComment != null) {
        commentData.parentComment.increment("commentCount")
        commentData.parentComment.save()
      }
      mixpanel.track("posted comment")
      onSuccess(newComment)
    }, (error: any) => {
      onError(error)
    });
  }

  static getComment(commentId: string) {
    const query = new Parse.Query("Comment");
    query.equalTo("objectId", commentId);
    query.include("author");
    query.include("parentPost");
    query.include("parentComment");
    return query.first().then((result) => {
        if (!result) {
            console.log(`No comment found for ID: ${commentId}`);
            return undefined;
        }
        return result;
    }).catch((error) => {
        console.error(`Error fetching comment with ID ${commentId}: `, error);
        return undefined;
    });
}

  static async getReaction(comment: Parse.Object, account: Parse.Object) {
    const reactions = comment.relation('reactions');
    const query = reactions.query();
    query.equalTo('reactor', account);

    const existingReaction = await query.first();
    return existingReaction
  }

  static reactToComment(comment: Parse.Object,
    reactionKey: string,
    reactor: Parse.Object,
    currentReaction: Parse.Object | undefined,
    onSuccess: (newReaction: Parse.Object | undefined) => void, onError: (message: any) => void) {
    if (currentReaction) {
      SeamCommentRepository.removeReaction(comment, currentReaction, onSuccess)
    } else {
      SeamCommentRepository.addReaction(comment, reactionKey, reactor, onSuccess)
    }
  }

  private static addReaction(comment: Parse.Object, reactionKey: string, reactor: Parse.Object, onSuccess: (newReaction: Parse.Object | undefined) => void) {
    const Reaction = Parse.Object.extend('CommentReaction');
    const newReaction = new Reaction()
    newReaction.set("comment", comment)
    newReaction.set("reactionKey", reactionKey)
    newReaction.set("reactor", reactor)
    newReaction.save().then(async (savedReaction: Parse.Object) => {
      const reactionRelation = comment.relation("reactions")
      reactionRelation.add(savedReaction)
      comment.increment("reactionCount")
      comment.save();
      const isOwnAccount = reactor == comment.get("author");
      !isOwnAccount && sendCommentLikeNotification(reactor, comment.get("author"), comment.get("parentPost").id);
      onSuccess(newReaction)
    }, (error: any) => {
      console.error(error)
    });
  }

  private static removeReaction(comment: Parse.Object, existingReaction: Parse.Object, onSuccess: (newReaction: Parse.Object | undefined) => void) {
    existingReaction.destroy().then((deleted) => {
      comment.decrement("reactionCount")
      comment.save()
      onSuccess(undefined)
    }, (error) => {
      console.error(error)
    });
  }

  static deleteComment(comment: Parse.Object) {
    comment.set("isDeleted", true)
    comment.save();
    mixpanel.track("delete comment")
    window.emitter.emit("SEAM_EVENT_DELETE_COMMENT", comment.id)
  }

  static async flag(comment: Parse.Object, currentUserId: string,) {
    try {
      let existingReports = comment.get("reported") || [];
      if (!existingReports.includes(currentUserId)) {
        existingReports.push(currentUserId);
        comment.set("reported", existingReports);
        await comment.save();

        // Gather details for the email
        const commentId = comment.id;
        const parentPostId = comment.get("parentPost").id;
        const authorId = comment.get("author").id;

        // Email subject and text
        const subject = `Seam: Comment Reported`;
        const text = `Comment with ID ${commentId} on Post with ID ${parentPostId} authored by User with ID ${authorId} has been reported by User with ID ${currentUserId}. Link: https://tomebooks.com/post/${parentPostId} `;

        // Send an email notification
        await Parse.Cloud.run('sendEmail', { subject, text });

        console.log('Comment reported and email notification sent');
      }
    } catch (error) {
      console.error('Error reporting comment:', error);
      throw error;
    }
  }

  static hasBeenFlaggedByMe(myUserId: string, comment: Parse.Object) {
    return comment?.get("reported").includes(myUserId)
  }
}