import { getProcessedProfilePictureURL } from "./helpers/ImageTransformers";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { Avatar } from "@mui/material";

const TomeUserItem = ({ user, title, subtitle, link, actions }) => {
  const processedAvatar = getProcessedProfilePictureURL(user.get('profilePhoto'));
  const seamNavigator = useSeamNavigator();

  return (
    <div className="flex items-center justify-between w-full h-auto">
      <div className="flex items-center space-x-4">
        <div onClick={() => seamNavigator.navigateTo(`/user/${user.get('profileId')}`)} className="flex cursor-pointer items-center justify-center">
          <Avatar src={processedAvatar} className="w-[32px] h-[32px]" />
        </div>
        <div className="flex flex-col items-start justify-center">
          <div
            onClick={() => {
              seamNavigator.navigateTo(link);
            }}
            className="cursor-pointer hover:underline"
          >
            {title}
          </div>
          <div className="text-gray-600 h-auto truncate">{subtitle}</div>
        </div>
      </div>
      {actions && <div className="flex items-center">{actions}</div>}
    </div>
  );
};

export default TomeUserItem;