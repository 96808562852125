import React, { useReducer, useEffect, useRef, useState, useMemo } from 'react';
import Parse from 'parse';
import { IonContent, IonModal, IonPage } from "@ionic/react";
import { ChevronLeft, PersonAddAlt1 } from "@mui/icons-material";
import { useSeamUser } from "../utils/SeamUserContext";
import Header from "../Navigation/Header";
import Feed from '../components/Feed';
import { useHistory } from 'react-router';
import ChannelFeedHeader from './ChannelFeedHeader';
import { Typography } from '@mui/material';
import { Seo } from '../utils/Seo';
import ChannelPageModal from './ChannelPageModal';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import DesktopSidebarWrapper from '../components/DesktopSidebarWrapper';
import { ReactComponent as StitchIcon } from "../assets/icons/stitchIcon.svg"
import SeamPillButton from '../Block-SDK/src/components/SeamPillButton';
import SeamShareBottomSheet from '../Comment/EngagementBar/SeamShareBottomSheet'
import { useMobile } from '../utils/MobileContext';
import mixpanel from 'mixpanel-browser';

const ChannelPage = ({ channelId, takingScreenshot }) => {
  const modal = useRef(null);
  const { account } = useSeamUser();
  const { contextObject } = useSeamNavigator();
  const { isMobile } = useMobile();
  const history = useHistory();
  const initialChannel = contextObject?.className === "Channel" ? contextObject : undefined;
  const [currentChannel, setCurrentChannel] = useState(initialChannel);
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const feedRef = useRef();

  const scrollToTop = () => {
    if (feedRef.current) {
      feedRef.current.scrollToTop();
    }
  };

  const fetchChannelData = async () => {
    if (isLoading || !channelId) return;
    setIsLoading(true);
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    query.equalTo("objectId", channelId);
    query.include("creator");

    try {
      const channel = await query.first();
      if (channel) {
        setCurrentChannel(channel);
      }
    } catch (error) {
      console.error("Error fetching channel: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const newChannel = currentChannel?.isNew();
    const sameDestination = contextObject && contextObject.id == currentChannel?.id && currentChannel?.id == channelId
    if (newChannel || sameDestination) { return }
    if (contextObject && contextObject.id != channelId) {
      setCurrentChannel(contextObject);
      return;
    }

    if (!isLoading) {
      fetchChannelData();
    };
  }, [channelId, contextObject, account]);

  const channelQuery = useMemo(() => {
    const Channel = Parse.Object.extend('Channel');
    const channelPointer = new Channel();
    channelPointer.id = channelId;
    const query = new Parse.Query('Post');
    query.equalTo('collection', { "__type": "Pointer", "className": "Channel", "objectId": channelId });
    query.descending('createdAt');
    query.include(['author', 'parentPost', 'parentPost.author']);

    return query;
  }, [channelId]);

  const HeaderComponent = () => (
    <ChannelFeedHeader
      currentChannel={currentChannel}
      updateChannel={(newChannel) => { setCurrentChannel(newChannel) }}
      loading={isLoading}
      openModal={() => setIsModalOpen(true)}
      darkMode={darkMode}
      takingScreenshot={takingScreenshot}
    />
  );

  const leftAction = () => {
    if (currentChannel && currentChannel.isNew()) {
      const confirmLeave = window.confirm(
        "The collection hasn't been saved and will be deleted unless you edit it. Are you sure you want to leave?"
      );
      if (confirmLeave) {
        history.go(-1);
      } else {
        // Stay on the page
      }
    } else {
      history.go(-1);
    }
  };

  // Prepare background style
  const channelBackground = currentChannel?.get("backgroundImage");
  const bgGradientStart = currentChannel?.get("color1");
  const bgGradientEnd = currentChannel?.get("color2");
  let backgroundStyle;
  let darkMode = undefined;
  let textColor = 'black';
  darkMode = (currentChannel?.get("fontColor") === "white")
  if (darkMode !== undefined) {
    textColor = darkMode ? 'white' : 'black';
  }

  if (channelBackground) {
    backgroundStyle = { backgroundImage: `url(${channelBackground})`, backgroundColor: "white" };
  } else if (bgGradientEnd) {
    backgroundStyle = { backgroundImage: `linear-gradient(to bottom, ${bgGradientStart}, ${bgGradientEnd})`, backgroundColor: "white" };
  } else {
    backgroundStyle = { backgroundColor: bgGradientStart ?? "white" };
  }

  const seoTitle = `${currentChannel?.get("emoji")} ${currentChannel?.get("name")}`;
  const seoDescription = `Start collecting posts from ${currentChannel?.get("name")} today!`;
  const seoImage = `https://storage.googleapis.com/tome-posts/channels/${currentChannel?.id}.jpg`;

  const tapShareButton = async (event) => {
    if (!account) { return }
    mixpanel.track("share clicked")
    setIsSharing(true);
  }

  const leftIcon = () => {
    if (takingScreenshot) return null;

    return (
      <div onClick={leftAction} className={`${isMobile ? 'ml-4' : 'ml-[48px]'}`}>
        <SeamPillButton
          icon={<ChevronLeft className={`text-${textColor} h-4 w-4`} />}
          label={null}
          darkMode={darkMode}
          onClick={() => { }}
          isOnChannelPage={true}
        />
      </div>
    )
  }

  const rightIcon = () => {
    return (
      <>
        {currentChannel && account && (
          <div className={`${isMobile ? 'mr-4' : 'mr-[48px]'}`}>
            <SeamPillButton
              icon={<StitchIcon className={`text-${textColor} h-4 w-4`} />}
              label={null}
              darkMode={darkMode}
              onClick={tapShareButton}
              isOnChannelPage={true}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <IonPage className="profilePage w-full h-full" style={{ marginTop: 'calc(-1 * env(safe-area-inset-top))', height: 'calc(100vh + env(safe-area-inset-top)' }}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        author={"@" + currentChannel?.get('creator')?.get("objectId")}
        imageURL={seoImage}
        url={"https://www.tomebooks.com/collection/" + currentChannel?.id}
      />
      <Header
        leftIcon={isMobile && leftIcon()}
        rightAction={() => { }}
        leftAction={leftAction}
        leftActionOnMobileApp={true}
        rightIcon={isMobile && rightIcon()}
        centerIcon={isMobile ? <></> : null}
        includePadding={true}
        scrollToTop={scrollToTop}
        darkMode={darkMode}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <div className={`absolute top-0 w-full`}
          style={{
            ...backgroundStyle,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: 'calc(100% + env(safe-area-inset-top))',
            marginTop: 'calc(-1 * env(safe-area-inset-top))',
            zIndex: -1
          }}
        />
        <DesktopSidebarWrapper showDesktopHeader={true} leftIcon={leftIcon()} rightIcon={rightIcon()} darkMode={darkMode}>
          <div className="h-full w-full overflow-y-auto flex items-start justify-start" style={{ paddingTop: 'calc(env(safe-area-inset-top)' }}>
            {currentChannel?.get("isDeleted") ? (
              <div className="h-full w-full flex items-center justify-center">
                <Typography variant="h2" className="text-[#8A8A8A]">
                  This collection has been deleted.
                </Typography>
              </div>
            ) : (
              <Feed
                ref={feedRef}
                postQuery={channelQuery}
                Header={HeaderComponent}
                onPostsLoaded={() => { }}
                dependencies={[channelId]}
                isOnChannelPage={true}
                darkMode={darkMode}
              />
            )}
          </div>
          {isModalOpen && <IonModal
            isOpen={isModalOpen}
            onDidDismiss={() => {
              setIsModalOpen(false)
            }}
            swipeToClose={false}
            canDismiss={isMobile ? true : false}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
          >
            <ChannelPageModal
              currentChannel={currentChannel}
              updateChannel={(newChannel) => { setCurrentChannel(newChannel) }}
              closeModal={() => setIsModalOpen(false)}
            />
          </IonModal>}
          {(isSharing) && (
            <IonModal
              ref={modal}
              isOpen={isSharing}
              initialBreakpoint={isSharing ? 0.3 : 1}
              breakpoints={isSharing ? [0, 0.3] : [0, 1]}
              canDismiss={true}
              showBackdrop={true}
              onDidDismiss={() => {
                setIsSharing(false);
              }}
            >
              <div style={{ marginBottom: 'env(safe-area-inset-bottom)' }} className="flex flex-col justify-center w-auto h-full my-6 px-4">
                {isSharing && <SeamShareBottomSheet type="collection" object={currentChannel} />}
              </div>
            </IonModal>
          )}
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default ChannelPage;