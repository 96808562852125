import { ChevronRightRounded, CloseRounded } from "@mui/icons-material";
import TomeHeaderBar from "../Block-SDK/src/components/TomeHeaderBar";
import { TomeButton } from "../components/TomeButton";
import { useSeamUser } from "../utils/SeamUserContext";
import UsernameWithAvatar from "../components/UsernameWithAvatar";
import { getProcessedChannelPhotoURL } from "../components/helpers/ImageTransformers";
import TomeCollectionRepository from "../Channels/TomeCollectionRepository";
import SeamStar from "../assets/seam-star.png";
import { BlockTypes } from "../Block-SDK/src/blocks/types";
import { TextField, Typography } from "@mui/material";
import BlockSelectorModal from "../Block-SDK/src/Composer/BlockSelectorModal";
import BlockFactory from "../Block-SDK/src/blocks/BlockFactory";
import { IonContent, IonModal, IonTextarea } from "@ionic/react";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useMobile } from "../utils/MobileContext";
import { ATTACHMENT_EDITOR_HEIGHT } from "./Composer";
import TagInputRow from "./TagInputRow";

const ATTACHMENT_MODAL_HEIGHT = {
  CLOSED: 0,
  HALF: 0.33,
  OPEN: 1
};

type ComposerMainViewProps = {
  channel: any;
  navigateToChannelSheet: () => void;
  createPost: (description: string, ratio: number | undefined) => void;
  finishEditingAttachment: (selectedAttachmentData: any) => void;
  dismissComposer: () => void;
  selectedAttachmentData: any;
  tags: any;
  onTagsChange: any;
  hasSpoilers: boolean;
  setHasSpoilers: (hasSpoilers: boolean) => void;
};

const ComposerMainView: React.FC<ComposerMainViewProps> = ({
  channel, 
  navigateToChannelSheet, 
  createPost,
  finishEditingAttachment,
  dismissComposer,
  selectedAttachmentData,
  tags,
  onTagsChange,
  hasSpoilers,
  setHasSpoilers,
}) => {
  const { account } = useSeamUser();
  const { isMobile } = useMobile();

  const initialState = {
    description: "",
    attachmentModalHeight: ATTACHMENT_MODAL_HEIGHT.HALF,
    selectedAttachment: null,
  };

  function reducer(state: any, action: { type: any, payload: any; }) {
    switch (action.type) {
      case "updateDescription":
        return { ...state, description: action.payload }
      case "changeAttachmentModalHeight":
        return { ...state, attachmentModalHeight: action.payload }
      case "discardAttachment":
        return { ...state, selectedAttachmentData: undefined, selectedAttachment: undefined, attachmentModalHeight: ATTACHMENT_MODAL_HEIGHT.HALF }
      case "openAttachment":
        return { ...state, selectedAttachment: action.payload, attachmentModalHeight: ATTACHMENT_MODAL_HEIGHT.OPEN };
      case "addLinkAttachment":
        return { ...state, selectedAttachment: BlockTypes["link"], attachmentModalHeight: ATTACHMENT_MODAL_HEIGHT.CLOSED };
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const attachmentModalRef = useRef<HTMLIonModalElement>(null);
  const attachmentRef = useRef<HTMLDivElement>(null);
  const textFieldRef = useRef<HTMLDivElement | null>(null);
  let height = attachmentRef.current?.offsetHeight;
  let width = attachmentRef.current?.offsetWidth;
  let ratio = width && height ? width / height : undefined;

  useEffect(() => {
    if (textFieldRef.current && state.attachmentModalHeight === ATTACHMENT_EDITOR_HEIGHT.HALF) {
      setTimeout(() => {
        textFieldRef.current?.focus(); // autofocus textinput
      }, 650); // need to wait for attachmentmodal to render or loses focus, any shorter and weirdness occurs
    }
  }, []);  

  useEffect(() => {
    if (attachmentModalRef.current) {
      attachmentModalRef.current.setCurrentBreakpoint(state.attachmentModalHeight);
    }
  }, [state.attachmentModalHeight]);

  useEffect(() => {
    if (selectedAttachmentData) {
      dispatch({ type: "changeAttachmentModalHeight", payload: ATTACHMENT_MODAL_HEIGHT.CLOSED });
    }
  }, [selectedAttachmentData]);

  const handleDescriptionChange = (e: any) => {
    const value = e.target.value;
    dispatch({ type: "updateDescription", payload: value });
  
    // Regular expression to detect URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = value.match(urlRegex);
  
    if (urls && urls.length > 0) {
      const linkUrl = urls[0];
      dispatch({ type: "addLinkAttachment", payload: linkUrl });
      finishEditingAttachment({
        type: "link",
        data: { url: linkUrl },
      });
    }
  };

  const AttachmentEditor = () => {
    return (
      <div className="h-full w-auto" style={{ boxSizing: "border-box" }}>
        <div className="px-4" style={{ position: BlockFactory.doesBlockEditFullscreen(state.selectedAttachment?.type) ? "absolute" : "relative", paddingBottom: 16, width: "100%", boxSizing: 'border-box' }}>
          <TomeHeaderBar
            leftComponent={null}
            rightComponent={<CloseRounded onClick={() => dispatch({ type: "discardAttachment", payload: null })} className="text-black"  />}
            centerComponent={BlockFactory.doesBlockEditFullscreen(state.selectedAttachment?.type) ? null : <h3>{state.selectedAttachment?.displayName}</h3>}
          />
        </div>
        <BlockSelectorModal
          selectedBlockType={state.selectedAttachment?.type ?? {}}
          initialBlockData={selectedAttachmentData}
          setSelectedBlockData={(data: any) => {
            // if (commentCompletionOverride) {
            //   commentCompletionOverride(data);
            //   dismiss();
            //   return;
            // }
            finishEditingAttachment(data);
            dispatch({ type: "changeAttachmentModalHeight", payload: ATTACHMENT_MODAL_HEIGHT.CLOSED });
          }}
        />
      </div>
    );
  }

  const AttachmentOptions = () => {
    return (
      <div className="flex flex-col h-full overflow-y-auto space-y-4 p-4">
        {
          Object.entries(BlockTypes)
          .filter(([key, block]) => block.doesBlockPost) // Only include blocks that can post
          .map(([key, block]) => (
            <div
              key={block.type}
              className="flex flex-row space-x-2 w-full items-center justify-start"
              id={block.type}
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: "openAttachment", payload: block });
              }}
            >
              <img src={block.icon} className="w-6 h-6" alt={block.displayName} />
              <Typography variant="h3" className="my-0 text-center">{block.displayName}</Typography>
            </div>
          ))
        }
      </div>
    );
  };

  const AttachmentModal = useMemo(() => {      
    return (
      <IonModal
        className={`ion-disable-focus-trap`}
        ref={attachmentModalRef}
        isOpen={state.attachmentModalHeight !== ATTACHMENT_MODAL_HEIGHT.CLOSED}
        canDismiss={state.attachmentModalHeight === ATTACHMENT_MODAL_HEIGHT.CLOSED}
        showBackdrop={false}
        backdropDismiss={false}
        backdropBreakpoint={1} // allows the backdrop to be tappable
        initialBreakpoint={state.attachmentModalHeight}
        breakpoints={[
          ATTACHMENT_MODAL_HEIGHT.CLOSED,
          ATTACHMENT_MODAL_HEIGHT.HALF,
          ATTACHMENT_MODAL_HEIGHT.OPEN,
        ]}
        handle={false}
        style={{ marginTop: "env(safe-area-inset-top)" }}
      >
        <IonContent>
          <div className="flex flex-col rounded-[20px] border-t border-black/5 justify-between h-full">
            {state.selectedAttachment ? <AttachmentEditor /> : <AttachmentOptions />}
          </div>
        </IonContent>
      </IonModal>
    );
  }, [state.attachmentModalHeight, state.selectedAttachment]);

  const CollectionSelectRow = () => {
    if (!account) return null;

    let channelPhoto = getProcessedChannelPhotoURL(channel?.get("headerImage"));
    if (!channel) {
      channel = TomeCollectionRepository.emptyCollection({
        creator: account,
        name: "New Collection",
        emoji: "😊",
        headerImage: "",
      });
    }

    return (
      <div className="flex flex-row items-center justify-between w-full">
        <h4 className="my-0 uppercase opacity-50">Posting to:</h4>
        <div className="flex flex-col w-auto items-end">
          <div className="flex w-auto h-full items-center space-x-2" onClick={navigateToChannelSheet}>
            <img
              src={channelPhoto || SeamStar}
              className="w-[16px] h-full rounded-[4px]"
            />
            <h2 className="tome-text-gradient my-0">{channel.get("name")}</h2>
            <ChevronRightRounded style={{ fontSize: '24px' }} className="text-tome-pink-gradient-start" />
          </div>
        </div>
      </div>
    )
  }

  const SelectedAttachment = useMemo(() => {
    if (!selectedAttachmentData) {
      return null;
    }

    return (
      <div ref={attachmentRef} className="relative flex flex-row justify-center h-full max-w-full w-auto overflow-visible">
        <div
          className="absolute z-10 top-2 right-2 rounded-full bg-seam-gray p-1"
          onClick={() => {
            dispatch({ type: "discardAttachment", payload: null });
            finishEditingAttachment(null);
          }}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </div>
        {BlockFactory.getFeedComponent(selectedAttachmentData, () => {})}
      </div>
    );
  }, [selectedAttachmentData]); 

  return (
    <div className="flex flex-col h-full">
      <TomeHeaderBar
        leftComponent={<CloseRounded onClick={() => {
          dispatch({ type: "changeAttachmentModalHeight", payload: ATTACHMENT_MODAL_HEIGHT.CLOSED });
          dismissComposer();
        }} 
        style={{ fontSize: '20px' }} className="text-black" />}
        rightComponent={<TomeButton onClick={() => createPost(state.description, ratio)} containerClass={`flex items-center text-white justify-center rounded-[62px] py-3 px-6`} label={<h4 className="my-0 uppercase">POST</h4>} gradient={"linear-gradient(167deg, #FB3939 -1.46%, #EE39FB 108.77%)"} />}
        centerComponent={<h3 className="my-0">Create a Post</h3>}
      />
      {/* 250px bottom margin needed to place the tag row above the attachment modal when its at half height */}
      <div className={`${state.attachmentModalHeight === ATTACHMENT_MODAL_HEIGHT.HALF ? `mb-[33vh]` : 'mb-6'} py-2 px-4 flex flex-col h-full space-y-4 overflow-y-scroll hide-scrollbar items-start justify-start`}>
        <UsernameWithAvatar account={account} color={'black'} avatarSize={24} />
        <CollectionSelectRow />
        <TextField
          multiline
          minRows={1} // Ensures it starts with a single line
          maxRows={Infinity} // Allows expansion as more lines are typed
          inputRef={textFieldRef}
          placeholder="What's on your mind?"
          value={state.description}
          onChange={handleDescriptionChange}
          fullWidth
          variant="standard" 
          InputProps={{
            disableUnderline: true,
            style: {
              resize: "none", 
              padding: 0,
              fontSize: "1rem",
            },
          }}
          sx={{
            "& .MuiInputBase-root": {
              border: "none", // Remove border
              boxShadow: "none", // Remove shadow
            },
            "& .MuiInputBase-input": {
              margin: 0, // Remove inner margin
            },
          }}
        />
        {SelectedAttachment}
        <div className="h-full"></div>
        <TagInputRow tags={tags} onTagsChange={onTagsChange} hasSpoilers={hasSpoilers} setHasSpoilers={setHasSpoilers}/>
        {AttachmentModal}
      </div>
    </div>
  )
}

export default ComposerMainView;