import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSeamUser } from './utils/SeamUserContext';
import { IonContent, IonPage } from '@ionic/react';
import Header from './Navigation/Header';
import TomeUserItem from './components/TomeUserItem';
import { formatPostDate } from './utils/formatPostDate';
import { SubscribeToNotifications, UnsubscribeFromNotifications } from './components/NotificationRegistrationHelpers';
import Parse from 'parse';
import useSearchReducer from './utils/useSearchReducer';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import NotifsOff from './assets/icons/NotifsOff.svg';
import NotifsOn from './assets/icons/NotifsOn.svg';
import { useMobile } from './utils/MobileContext';
import { Virtuoso } from 'react-virtuoso';
import DesktopSidebarWrapper from './components/DesktopSidebarWrapper';
import { LoadingSkeleton } from './components/LoadingSkeleton';
import { useHistory } from 'react-router';
import { ChevronLeft } from '@mui/icons-material';

const NotificationsPage = () => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [state, dispatch] = useSearchReducer();
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState<any>(false);
  const [currentSubscription, setCurrentSubscription] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (!account) return;
      // first check if we have an active firebase messaging token
      try {
        let permission = await FirebaseMessaging.checkPermissions()
        const allowed = permission.receive;
        if (!allowed) {
          return;
        }

        const { token } = await FirebaseMessaging.getToken(isMobileApp ? undefined : { vapidKey: process.env.REACT_APP_VAPID_KEY });
        if (!token) {
          setIsSubscribed(false)
          return;
        }

        if (isMobileApp) {
          const AppleSubscription = Parse.Object.extend("AppleSubscription");
          const query = new Parse.Query(AppleSubscription);
          query.equalTo("token", token);
          const subscription = await query.first();
          if (subscription) {
            setCurrentSubscription(subscription);
            setIsSubscribed(true);
          }
        }
      } catch (error) {
        // likely the user has blocked notifications on web
      }
    };
    fetchSubscriptionStatus();
  }, [account]);

  const removeSubscription = async () => {
    if (currentSubscription === undefined) return;
    await UnsubscribeFromNotifications();
    setCurrentSubscription(undefined);
  };

  useEffect(() => {
    if (!account) return;
    const query = new Parse.Query("Notifications");
    query.equalTo("recipientAccount", account);
    query.include("actorAccount");
    query.limit(25);
    query.descending("createdAt");

    query.find().then((results: any) => {
      setNotifications(results);
      setIsLoading(false);
      getUnreadNotifications(results);
    });

    async function setupSubscription() {
      const subscription = await query.subscribe();
      subscription.on('create', (object) => {
        setNotifications((prevData: any) => [object, ...prevData]);
        setUnreadNotificationCount(1);
        window.emitter.emit("SEAM_EVENT_TOTAL_NOTIFICATIONS", unreadNotificationCount + 1);
      });
    };
    setupSubscription();
  }, []);

  const getUnreadNotifications = (notifications: any) => {
    let unreadCount = 0;
    for (var notif of notifications) {
      if (notif.get("unread") === true) unreadCount++;
    }
    setUnreadNotificationCount(unreadCount);
  };

  const renderNotifTitle = (notification: any) => {
    return (
      <h3 className="my-0 text-black w-full truncate text-base hover:underline">
        {notification.get("description")}
      </h3>
    );
  };

  const renderNotifSubtitle = (notification: any) => {
    return (
      <h4 className="my-0 text-black text-[16px] truncate w-full text-center items-center">
        {formatPostDate(notification.get("createdAt"))}
      </h4>
    );
  };

  const UnreadDot = () => (
    <div className="w-2 h-2 bg-[#EE39FB] rounded-full"></div>
  );

  const handleNotifClick = async () => {
    if (!account) return;
    if (isSubscribed) {
      await removeSubscription();
      setCurrentSubscription(undefined);
      setIsSubscribed(false);
    } else {
      const sub = await SubscribeToNotifications(account);
      if (sub) {
        setCurrentSubscription(sub);
        setIsSubscribed(true);
      }
    }
  };

  const handleSearch = (searchString: string) => {
    dispatch({ type: 'UPDATE_SEARCH_STRING', payload: searchString });
  };

  const setNotificationRead = async (notification: any) => {
    notification.set("unread", false);
    notification.save().then(() => {
      setUnreadNotificationCount(unreadNotificationCount - 1);
      window.emitter.emit("SEAM_EVENT_TOTAL_NOTIFICATIONS", unreadNotificationCount - 1);
    });
  };

  const itemContent = (index: any, notification: any) => {
    if (!notification) return null;

    return (
      <div className="px-4 py-4 border-b w-full border-gray-500/20" onClick={() => { setNotificationRead(notification) }}>
        <TomeUserItem
          user={notification?.get("actorAccount")}
          title={renderNotifTitle(notification)}
          subtitle={renderNotifSubtitle(notification)}
          link={notification.get("url")}
          actions={notification?.get("unread") ? <UnreadDot /> : <></>}
        />
      </div>
    );
  };

  const isEmpty = notifications.length === 0 && !isLoading;

  const EmptyState = () => {
    return (
      <div className="px-6 mx-6 flex flex-col items-center justify-start w-full max-w-[400px] h-full space-y-4 my-6">
        <div className="flex justify-center items-center rounded-full bg-[#efefef] p-6 border-2 border-seam-black/[5%]">
          <h1 className="">🔔</h1>
        </div>
        <h2 className="text-seam-black text-center">No Notifications</h2>
        <h3 className="text-seam-black/[33%] text-center">Activity from other users interacting with your content will show up here.</h3>
      </div>
    );
  };

  const leftIcon = () => {
    return (
      <div onClick={isMobileApp ? () => { } : () => history.go(-1)}>
        <ChevronLeft style={{ fontSize: '24px' }} className="text-black cursor-pointer border border-black/10 rounded-full" />
      </div>
    )
  }

  const rightIcon = () => {
    return (
      <div className="" onClick={handleNotifClick}>
        {isSubscribed ? (
          <img src={NotifsOn} alt="Notifications On" className="w-6 h-6 fill-seam-black cursor-pointer" />
        ) : (
          <img src={NotifsOff} alt="Notifications Off" className="h-6 w-6 fill-seam-black cursor-pointer" />
        )}
      </div>
    )
  }

  return (
    <IonPage id={"notificationsTab"}>
      <Header
        leftIcon={isMobile && leftIcon()}
        rightIcon={isMobile && rightIcon()}
        showSearchBar={false}
        searchState={undefined}
        dispatch={undefined}
        onSearchSubmit={handleSearch}
        scrollToTop={() => { }}
        centerIcon={undefined}
        darkMode={false}
      />
      <IonContent fullscreen={true} scrollY={false}>
        {/* darkmode undefined since no darkmode needed -- keeps bg of leftnav transparent on desktop */}
        <DesktopSidebarWrapper showDesktopHeader={true} leftIcon={!isMobileApp && leftIcon()} centerIcon={null} rightIcon={!isMobileApp && rightIcon()} navSection={"notifications"} darkMode={undefined}>
          <div className={`flex flex-col w-full max-w-[720px] h-full`}>
            <div className="w-full h-full">
              <div className={`w-auto h-full flex flex-col items-center justify-start`}>
                {isLoading ? (
                  <div className={`w-full h-full flex flex-col`}>
                    {LoadingSkeleton()}
                  </div>
                ) : isEmpty ? (
                  <EmptyState />
                ) : (
                  <Virtuoso
                    className={`hide-scrollbar w-full ion-content-scroll-host`}
                    data={notifications}
                    itemContent={itemContent}
                    components={{
                      Footer: () => <div style={{ height: '150px' }}></div>
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default NotificationsPage;