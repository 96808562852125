import React, { useState, useEffect } from 'react';
import ToastContent from './ToastContent';

const ToastNotification = () => {
  const [toastContent, setToastContent] = useState(null);

  useEffect(() => {
    const showToast = (content) => {
      setToastContent(content);
    };

    window.emitter.on("SEAM_EVENT_ADDED_TO_COLLECTION", showToast);
    window.emitter.on("SEAM_EVENT_SHOW_TOAST", (notif) => showToast(notif.content));

    return () => {
      window.emitter.off("SEAM_EVENT_ADDED_TO_COLLECTION", showToast);
      window.emitter.off("SEAM_EVENT_SHOW_TOAST", showToast);
    };
  }, []);

  return (
    <ToastContent
      content={toastContent}
      duration={5000}
      onClose={() => setToastContent(null)}
    />
  );
};

export default ToastNotification;