import { AutoAwesome } from "@mui/icons-material";
import { useEffect, useMemo, useReducer, useRef, createContext, useState } from "react";
import { useMobile } from "../utils/MobileContext";
import { IonContent, IonModal } from "@ionic/react";
import { Typography } from "@mui/material";
import { useSeamUser } from "../utils/SeamUserContext";
import TomePostRepository, { PostType } from "../Post/TomePostRepository";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import TomeCollectionRepository from "../Channels/TomeCollectionRepository";
import ComposerMainView from "./ComposerMainView";
import ChannelSelectionPage from "./ChannelSelectionPage";
import { motion } from "framer-motion";

export const ATTACHMENT_EDITOR_HEIGHT = {
  CLOSED: 0,
  HALF: 0.33,
  OPEN: 1
};

export const COMPOSER_VIEW = {
  MAIN: "main",
  CHANNEL_SELECTION: "channelSelection",
}

export const ComposerContext = createContext<{
  state: any;
  dispatch: React.Dispatch<{ type: any, payload: any }>
} | null>(null);

function Composer({
  includeLabel = false
}) {
  
  const { account, userCreatedChannels } = useSeamUser();
  const { isMobile } = useMobile();
  const seamNavigator = useSeamNavigator();

  const initialState = {
    isOpen: false,
    selectedAttachmentData: null,
    tags: [],
    hasSpoilers: false,
    selectedChannel: userCreatedChannels[0],
    currentView: "main",
    firstRender: true,
  };

  function reducer(state: any, action: { type: any; payload: any; }) {
    switch (action.type) {
      case "open":
        return { ...state, isOpen: true, firstRender: true, };
      case "dismiss":
        return { ...initialState };
      case "selectChannel":
        return { ...state, selectedChannel: action.payload };
      case "finishEditingAttachment": 
        return { ...state, selectedAttachmentData: action.payload };
      case "updateTags":
        return { ...state, tags: action.payload };
      case "setHasSpoilers": 
        return { ...state, hasSpoilers: action.payload };
      case "setView":
        if (!Object.values(COMPOSER_VIEW).includes(action.payload)) {
          throw new Error(`Invalid view: ${action.payload}`);
        }
        return { ...state, currentView: action.payload, firstRender: false };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    // after the userContext fetches all the user channels, we should select the most recent one as a default
    if (state.selectedChannel == undefined && userCreatedChannels.length > 0) {
      dispatch({ type: "selectChannel", payload: userCreatedChannels[0] });
    }
  }, [userCreatedChannels]);

  const PostToast = () => {
    return (
      <div className="flex items-center justify-between h-auto w-auto">
        <h4 className="text-[#FEFEFE]/70 mr-2">You've added a post to</h4>
        <div
          onClick={() => seamNavigator.navigateTo(`/collection/${state.selectedChannel.id}, state.selectedChannel`)}
          className="flex flex-row items-center justify-center cursor-pointer w-auto max-w-[50%] truncate"
        >
          <span className="no-underline">{state.selectedChannel?.get("emoji")}</span>
          <h4 className="underline truncate w-full">{state.selectedChannel?.get("name")}</h4>
        </div>
      </div>
    )
  }

  const handlePostCompletion = (post: Parse.Object) => {
    window.emitter.emit("SEAM_EVENT_POST_SUCCESSFUL", post);
    dispatch({ type: "dismiss", payload: null });
    const toastContent = PostToast();

    window.emitter.emit("SEAM_EVENT_ADDED_TO_COLLECTION", toastContent);
  }

  const createPost = async (description: string, ratio: number | undefined) => {
    if (account == undefined) { return; }

    let channel = state.selectedChannel;
    if (!channel) {
      channel = await TomeCollectionRepository.createCollection({
        creator: account,
        name: "New Collection",
        emoji: "📚",
        headerImage: ""}
      );
      dispatch({ type: "selectChannel", payload: channel });
    }

    TomePostRepository.createPost({
      author: account,
      collection: channel,
      tags: state.tags,
      hasSpoilers: state.hasSpoilers,
      text: description,
      blockData: state.selectedAttachmentData,
      mentions: [],
      type: PostType.post,
      aspectRatio: ratio,
      parentPost: undefined,
    }, handlePostCompletion, (message: any) => { });
  }

  const renderContent = () => {
    const isMainView = state.currentView === COMPOSER_VIEW.MAIN;

    const motionProps = {
      key: state.currentView,
      initial: state.firstRender ? {} : { x: isMainView ? 50 : -50, opacity: 0 },
      animate: state.firstRender ? { opacity: 1 } : { x: 0, opacity: 1 },
      exit: { x: isMainView ? -50 : 50, opacity: 0 },
      transition: state.firstRender ? {} : { duration: 0.3 },
      style: { height: "100%" },
    };
  
    switch (state.currentView) {
      case COMPOSER_VIEW.MAIN:
        return (
          <motion.div {...motionProps}>
            <ComposerMainView
              channel={state.selectedChannel}
              navigateToChannelSheet={navigateToChannelSheet}
              createPost={createPost}
              finishEditingAttachment={finishEditingAttachment}
              dismissComposer={dismissComposer}
              selectedAttachmentData={state.selectedAttachmentData}
              tags={state.tags}
              onTagsChange={handleTagsChange}
              hasSpoilers={state.hasSpoilers}
              setHasSpoilers={setHasSpoilers}
            />
          </motion.div>
        );
      case COMPOSER_VIEW.CHANNEL_SELECTION:
        return (
          <motion.div {...motionProps}>
            <ChannelSelectionPage 
              channel={state.selectedChannel}
              changeSelectedChannel={changeSelectedChannel}
            />
          </motion.div>
        );
      default:
        return (
          <motion.div {...motionProps}>
            <ComposerMainView
              channel={state.selectedChannel}
              navigateToChannelSheet={navigateToChannelSheet}
              createPost={createPost}
              finishEditingAttachment={finishEditingAttachment}
              dismissComposer={dismissComposer}
              selectedAttachmentData={state.selectedAttachmentData}
              tags={state.tags}
              onTagsChange={handleTagsChange}
              hasSpoilers={state.hasSpoilers}
              setHasSpoilers={setHasSpoilers}
            />
          </motion.div>
        );
    }
  };
  

  const navigateToChannelSheet = () => {
    dispatch({ type: "setView", payload: COMPOSER_VIEW.CHANNEL_SELECTION });
  };

  const navigateToMainView = () => {
    dispatch({ type: "setView", payload: COMPOSER_VIEW.MAIN });
  }

  const dismissComposer = () => {
    dispatch({ type: "dismiss", payload: null })
  }

  const changeSelectedChannel = (newChannel: any) => {
    dispatch({ type: "selectChannel", payload: newChannel})
    navigateToMainView();
  }

  const finishEditingAttachment = (selectedAttachmentData: any) => {
    dispatch({ type: "finishEditingAttachment", payload: selectedAttachmentData })
  }

  const handleTagsChange = (updatedTags: string[]) => {
    dispatch({ type: "updateTags", payload: updatedTags });
  };

  const setHasSpoilers = (hasSpoilers: boolean) => {
    dispatch({ type: "setHasSpoilers", payload: hasSpoilers });
  }

  return (
    <ComposerContext.Provider value={{ state, dispatch }}>
      <div className="flex flex-col h-full">
        <div
          className="cursor-pointer h-full w-full flex flex-col items-center justify-center"
          onClick={() => {dispatch({ type: "open", payload: null })}}
          id="open-modal"
        >
          <div className="flex flex-row space-x-4 items-center justify-between">
            <div style={{backgroundImage: "linear-gradient(167deg, #FB3939 -1.46%, #EE39FB 108.77%)" }} className="rounded-full flex items-center justify-center h-6 w-6">
              <AutoAwesome className="w-[20px] h-[20px] self-center text-white" />
            </div>
            {includeLabel && <Typography variant="h3" className="text-center hover:underline">New Post</Typography>}
          </div>
        </div>
        <IonModal
          className={`ion-disable-focus-trap ${isMobile ? "" : "h-screen custom-modal-fullscreen"}`}
          ref={modal}
          isOpen={state.isOpen}
          canDismiss={true}
          showBackdrop={true}
          onDidDismiss={() => {
            dispatch({ type: "dismiss", payload: null });
          }}
          style={{ marginTop: "env(safe-area-inset-top)" }}
        >
          {/* <ComposerModalContent
            createPost={createPost}
            attachmentRef={attachmentRef}
          /> */}
          {renderContent()}
        </IonModal>
      </div>
    </ComposerContext.Provider>
  )
}

export default Composer;