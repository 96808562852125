import mixpanel from "mixpanel-browser";
import Parse from 'parse';

export const followAccount = (account, accountToFollow, refreshAccount) => {
  Parse.Cloud.run("followAccount", { account: account.id, accountToFollow: accountToFollow.id }).then(() => {
    mixpanel.track("account followed")
    refreshAccount();
  });
}

export const unfollowAccount = (account, accountToUnfollow, refreshAccount) => {
  Parse.Cloud.run("unfollowAccount", { account: account.id, accountToUnfollow: accountToUnfollow.id }).then(() => {
    mixpanel.track("account unfollowed")
    refreshAccount();
  });
}