const SeamPillButton = ({ icon, label, onClick, color, defaultBackground }) => {  
  return (
    <div
      onClick={onClick}
      className={`${defaultBackground ? `bg-tome-gray` : `bg-${color} bg-opacity-20`} flex items-center w-auto h-[40px] cursor-pointer rounded-full`}
    >
      <div className={`flex flex-row items-center justify-center space-x-2 m-3`}>
      {icon}
      {label && <h4 className={`text-${color} my-0 text-center leading-normal font-normal`}>{label}</h4>}
      </div>
    </div>
  );
};

export default SeamPillButton;