import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SearchFeed from './SearchFeed';
import { IonPage, IonContent } from '@ionic/react';
import Header from '../Navigation/Header';
import DesktopSidebarWrapper from '../components/DesktopSidebarWrapper';
import { useMobile } from '../utils/MobileContext';
import { useSeamUser } from '../utils/SeamUserContext';
import { ChevronLeft } from '@mui/icons-material';

const SearchPage = () => {
  const { tagName } = useParams();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const decodedTagName = decodeURIComponent(tagName);

  const StylizedTagName = () => (
    <div className="flex justify-center itemes-center w-auto">
      <h3 className="flex justify-self-center rounded-[8px] w-auto px-2 py-1 text-seam-blue bg-seam-green">
        <span className="max-w-[420px] truncate">#{decodedTagName}</span>
      </h3>
    </div>
  );

  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  return (
    <IonPage className="w-full h-full">
      <Header
        leftIcon={<ChevronLeft className="text-black py-2 px-6 cursor-pointer" />}
        rightIcon={null}
        leftAction={leftAction}
        centerIcon={<StylizedTagName />}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <DesktopSidebarWrapper>
          <div className="flex flex-col w-full h-full">
            <SearchFeed key={tagName} tagName={tagName} />
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default SearchPage;
