import React, { memo, useEffect, useState } from "react";
import Parse from "parse";
import CollectionItem from "./CollectionItem";
import { Skeleton } from "@mui/material";

function CollectionsCarousel({ creatorAccount, profileCustomizations, isSelfProfile, color, itemLimit = 0 }) {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!creatorAccount) return;
    const query = new Parse.Query("Channel");
    query.equalTo("creator", creatorAccount);
    query.equalTo("isDeleted", false);
    query.descending("createdAt");
    if (itemLimit > 0) {
      query.limit(itemLimit);
    }
    query.find().then((results) => {
      setLoading(false);
      setChannels(results);
    });
  }, [creatorAccount]);

  useEffect(() => {
    const handleCollectionCreated = (event) => {
      setChannels((prevChannels) => {
        const exists = prevChannels.some(channel => channel.id === event.collection.id);
        if (!exists) {
          return [event.collection, ...prevChannels];
        } else {
          return prevChannels.map(channel => channel.id === event.collection.id ? event.collection : channel);
        }
      });
    };

    const handleCollectionDeleted = (event) => {
      setChannels((prevChannels) =>
        prevChannels.filter((channel) => channel !== event.collection)
      );
    };

    window.emitter.on("SEAM_EVENT_CREATED_COLLECTION", handleCollectionCreated);
    window.emitter.on("SEAM_EVENT_DELETED_COLLECTION", handleCollectionDeleted);

    return () => {
      window.emitter.off("SEAM_EVENT_CREATED_COLLECTION", handleCollectionCreated);
      window.emitter.off("SEAM_EVENT_DELETED_COLLECTION", handleCollectionDeleted);
    };
  }, []);

  const LoadingSkeleton = () => (
    <div className="flex flex-col items-center justify-start w-auto h-auto px-2 space-y-2">
      <h2></h2>
      <div className="flex flex-row items-start justify-start w-full h-auto space-x-4">
        <Skeleton variant="rect" width={120} height={152} className="rounded-[12px]" />
      </div>
      <h3 className={`my-0 w-[120px]`}>{" "}</h3>
    </div>
  )

  const LoadingRow = () => (
    <>
      <LoadingSkeleton />
      <LoadingSkeleton />
      <LoadingSkeleton />
    </>
  );

  const bgGradientStart = profileCustomizations?.get("color1") || "#FFFFFF";
  const bgGradientEnd = profileCustomizations?.get("color2") || "#FFFFFF";
  const noBackgroundImage = !profileCustomizations?.get("backgroundImage");
  const defaultBackground = noBackgroundImage && bgGradientStart === "#FFFFFF" && bgGradientEnd === "#FFFFFF"

  return (
    <div className="flex flex-col items-center justify-start w-full space-y-6">
      <h2 className={`text-${color} my-0`}>{isSelfProfile ? 'My' : `${creatorAccount.get('profileId')}'s`} Collections</h2>
      <div className="px-4 flex flex-row overflow-x-auto items-start justify-start w-full hide-scrollbar">
        {channels.map((channel, index) => (
            <div key={index.toString()}>
              <CollectionItem
                channel={channel}
                creatorAccount={creatorAccount}
                color={color}
                defaultBackground={defaultBackground}
              />
            </div>
          )
        )}
        {loading && <LoadingRow />}
      </div>
    </div>
  );
}

export default memo(CollectionsCarousel);