import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Parse from "parse";

function UsernameTextField(props) {
  const { startingValue, onValueChange, onValidChange } = props;

  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(startingValue);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  async function validateUsername(username) {
    const valid = await Parse.Cloud.run("isValidUsername", {
      username: username,
    });
    return valid;
  }

  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (debouncedValue) {
      validateUsername(debouncedValue).then((isValid) => {
        setIsValid(isValid);
        onValidChange(isValid);
      });
    }
  }, [debouncedValue]);

  return (
    <TextField
      margin="normal"
      id="username"
      label="Claim your handle"
      name="username"
      error={!isValid}
      helperText={isValid ? "" : "That handle is taken, try another."}
      className={`text-seam-black w-full`}
      sx={{
        "& .MuiInputBase-input": {
          fontFamily: "Public Sans",
          color: 'black'
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none", // This removes the border
          },
          "&:hover fieldset": {
            border: "none", // Removes the border on hover
          },
          "&.Mui-focused fieldset": {
            border: "none", // Removes the border when the component is focused
          },
        },
        "& .MuiInputLabel-root": {
          color: '#606060', // Label color
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: '#606060', // Ensures the label color remains the same when focused
        },
        paddingLeft: 0,
      }}
      placeholder="Your future username"
      value={value}
      InputProps={{
        endAdornment: isValid ? (
          <InputAdornment position="end">✅</InputAdornment>
        ) : null,
      }}
      onChange={(event) => {
        const newUsername = event.target.value
          .replace(/[\s!@#$%^&*()_+.><?:;|\\\/]/g, "")
          .toLowerCase();
        setValue(newUsername);
        onValueChange(newUsername);
      }}
    />
  );
}

export default UsernameTextField;