import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";

export const FeedItemTagPill = ({ tagName, backgroundColor, textColor, backgroundGradient }) => {
  const seamNavigator = useSeamNavigator();
  return (
    <div
      onClick={() => seamNavigator.navigateTo(`/search/${tagName}`)}
      className={`p-1 bg-${backgroundColor} text-white text-[10px] font-normal uppercase tracking-[1px]`}
      style={backgroundGradient ? { backgroundImage: backgroundGradient } : {}}
    >
      <h4 className={`my-0 text-${textColor} whitespace-nowrap`}>
        #{tagName}
      </h4>
    </div>
  );
};
