import React, { createRef, memo, useEffect, useRef, useState } from "react";
import DiscoveryFeed from "../Discover/DiscoveryFeed";
import {
  IonPage,
  IonContent,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import Header from "../Navigation/Header";
import { NotificationsNone, Search } from "@mui/icons-material";
import useSearchReducer from "../utils/useSearchReducer";
import DesktopSidebarWrapper from "../components/DesktopSidebarWrapper";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { useMobile } from "../utils/MobileContext";

function HomePage() {
  const seamNavigator = useSeamNavigator();
  const { isMobile } = useMobile();
  const [state, dispatch] = useSearchReducer();
  const virtuosoRef = useRef(null);

  const handleToggleSearchBar = () => {
    dispatch({ type: "TOGGLE_SEARCH_BAR" });
  };

  useEffect(() => {
    window.addEventListener('toggleSearchBar', handleToggleSearchBar);

    return () => {
      window.removeEventListener('toggleSearchBar', handleToggleSearchBar);
    };
  }, []);

  const handleSearch = (searchString) => {
    dispatch({ type: "UPDATE_SEARCH_STRING", payload: searchString });
  };

  const scrollToTop = () => {
    virtuosoRef.current?.scrollToTop();
  };

  const handleNotifClick = () => {
    seamNavigator.navigateTo("/notifications");
  }

  return (
    <IonPage>
      <Header
        leftIcon={isMobile && <Search onClick={handleToggleSearchBar} style={{fontSize: '24px'}} className="text-black cursor-pointer" />}
        rightIcon={isMobile && <NotificationsNone onClick={handleNotifClick} style={{fontSize: '24px'}} className="text-black cursor-pointer" />}
        showSearchBar={state.showSearchBar}
        searchState={state}
        dispatch={dispatch}
        onSearchSubmit={handleSearch}
        scrollToTop={scrollToTop}
        centerIcon={undefined}
      />
      <IonContent scrollY={false} scrollX={false} scrollEvents={true}>
        <DesktopSidebarWrapper 
          showDesktopHeader={!isMobile}
          navSection={"feed"}
        >
          <div className="flex flex-col w-full h-full">
            <DiscoveryFeed ref={virtuosoRef} />
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
}

export default memo(HomePage);