import React, { memo, useState, useEffect } from "react";
import SeamPillButton from "../../Block-SDK/src/components/SeamPillButton";
import { useSeamUser } from "../../utils/SeamUserContext";
import SeamCommentRepository from "../SeamCommentRepository";
import { debounce } from "lodash";
import FavoriteIcon from "@mui/icons-material/Favorite";
import mixpanel from "mixpanel-browser";
import { useSeamNavigator } from "../../Navigation/SeamNavigatorContext";
import { ChatBubbleOutlineRounded, FavoriteBorderOutlined } from "@mui/icons-material";

const CommentEngagementBar = (props) => {
  const seamNavigator = useSeamNavigator();
  const { comment } = props;
  const replyCount = comment?.get("commentCount");
  const commentReactions = comment?.get("reactionCount");
  const hasReplies = replyCount > 0;
  const { account } = useSeamUser();
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [isReacting, setIsReacting] = useState(false);

  const getReplyString = (replyCount) => {
    if (replyCount === 0) {
      return "Reply";
    } else if (replyCount === 1) {
      return "1 Reply";
    } else {
      return `${replyCount} Replies`;
    }
  };

  const handleReplyButtonClick = () => {
    if (hasReplies) {
      // backwards compatibility for old comment detail page
      // comments made before 10/2024
      seamNavigator.navigateTo(`comment/${comment.id}`, comment);
    } else {
      window.emitter.emit("SEAM_EVENT_REPLY_TO_COMMENT", comment);
    }
    mixpanel.track("replies open");
  };

  const debouncedReactToPost = debounce(
    (comment, reactionKey, account, currentReaction) => {
      if (!isReacting) {
        setIsReacting(true);
        const onSuccess = (reaction) => {
          setSelectedReaction(reaction);
          setIsReacting(false);
        };

        SeamCommentRepository.reactToComment(
          comment,
          reactionKey,
          account,
          currentReaction,
          onSuccess
        );
      }
    },
    300
  );

  const tapLikeButton = async (event) => {
    debouncedReactToPost(comment, "Like", account, selectedReaction);
  };

  useEffect(() => {
    async function fetchCurrentReaction() {
      const currentReaction = await SeamCommentRepository.getReaction(comment, account)
      setSelectedReaction(currentReaction)
    }

    fetchCurrentReaction()
  }, [])

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        {/* Reply Button */}
        <SeamPillButton
          icon={<ChatBubbleOutlineRounded className="h-4 w-4 text-gray-600" />}
          label={getReplyString(replyCount)}
          onClick={handleReplyButtonClick}
        />

        <div className="flex-grow"></div>

        {/* Like/Reaction Button */}
        <SeamPillButton
          icon={
            selectedReaction ? (
              <FavoriteIcon className="text-[#ABD600] h-4 w-4" />
            ) : (
              <FavoriteBorderOutlined className="h-4 w-4 text-gray-600" />
            )
          }
          label={commentReactions > 0 ? commentReactions : null}
          onClick={tapLikeButton}
        />
      </div>
    </div>
  );
};

export default memo(CommentEngagementBar);
