import React, { useRef, forwardRef, useMemo } from 'react';
import Parse from 'parse';
import { useSeamUser } from '../utils/SeamUserContext';
import Feed from '../components/Feed';
import HomeFeedHeader from './HomeFeedHeader';
import { useAnimation } from 'framer-motion';
import { PostType } from '../Post/TomePostRepository';

const DiscoveryFeed = forwardRef((props, ref) => {
  const { account, refreshAccount } = useSeamUser();
  const controls = useAnimation();
  const feedRef = useRef();

  React.useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (feedRef.current) {
        feedRef.current.scrollToTop();
      }
    },
  }));

  const globalPostQuery = useMemo(() => {
    let query = new Parse.Query('Post');  
    query.equalTo('dataType', PostType.post);
    query.include('author');
    query.descending('createdAt');
    
    return query;
  }, []);

  const Header = ({ context }) => (
    <HomeFeedHeader state={context.state} controls={controls} account={account} />
  );

  return (
    <Feed
      ref={feedRef}
      postQuery={globalPostQuery}
      Header={Header}
      refreshAccount={refreshAccount}
      dependencies={[]}
      isOnChannelPage={false}
      color={'black'}
      defaultBackground={true}
    />
  );
});

export default DiscoveryFeed;