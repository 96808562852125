import { useSeamUser } from "../utils/SeamUserContext";
import { useSeamNavigator } from "./SeamNavigatorContext";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import { Avatar, Typography } from "@mui/material";
import { useIonRouter } from "@ionic/react";
import { Groups, Search, NotificationsNone, SearchOff } from "@mui/icons-material";
import Composer from "../Composer/Composer";

const LeftBannerNav = ({navSection, searchActive, darkMode}) => {
  const { account, hasUnreadNotifications } = useSeamUser();
  const router = useIonRouter();
  const seamNavigator = useSeamNavigator();
  const currentAccountProcessedProfilePhoto = getProcessedProfilePictureURL(
    account?.get("profilePhoto")
  );
  const hideNav = router.routeInfo.pathname.includes("/preview/");

  // let bgColor be transparent when darkMode is passed in as undefined
  // when rendering leftbannernav on home or notif page for example, leave bg transparent
  let textColor = darkMode ? 'white' : 'black';
  let bgColor = "transparent"
  if (darkMode !== undefined) {
    bgColor = darkMode ? "black" : "white";
  }

  const toggleSearchBar = () => {
    window.emitter.emit("SEAM_EVENT_TOGGLE_SEARCH");
  };
  
  const NavItem = ({ icon, label, onClick, isActive }) => {
    return (
      <div
        className="flex flex-row items-center justify-center w-auto space-x-4 cursor-pointer"
        onClick={onClick}
      >
        <div className="flex items-center justify-center">
          {icon}
        </div>
        <Typography
          variant="h3"
          className={`text-${textColor} text-center underline-offset-8 decoration-2 hover:underline ${
            isActive && 'underline'
          }`}
          style={{ fontWeight: isActive ? 700 : 400 }} 
        >
          {label}
        </Typography>
      </div>
    );
  };

  if (hideNav) return null;

  return (
    <div className="flex flex-col w-auto h-full items-center justify-start mx-auto p-4">
      <div className={`space-y-3 items-start flex w-full max-w-[206px] flex-col px-4 py-6 rounded-[24px] border border-seam-black/[5%] bg-${bgColor} bg-opacity-10`}>
        <NavItem 
          icon={<Groups className={`w-6 h-6 text-${textColor}`} />}
          label="Feed"
          onClick={() => seamNavigator.navigateTo("/home")}
          isActive={navSection === "feed" && !searchActive} 
        />
        <NavItem 
          icon={<Avatar src={currentAccountProcessedProfilePhoto} style={{width: '24px', height: '24px'}} />}
          label="Profile"
          onClick={() => seamNavigator.navigateTo(`/user/${account.get("profileId")}`)}
          isActive={navSection === "profile" && !searchActive} 
        />
        <Composer includeLabel={true}/> 
        <NavItem 
          icon={searchActive ? <SearchOff className={`w-6 h-6 cursor-pointer text-${textColor}`} /> : <Search className={`w-6 h-6 cursor-pointer text-${textColor}`}/>}
          label="Search"
          onClick={toggleSearchBar}
          isActive={searchActive} 
        />
        <NavItem 
          icon={
            <div className="flex flex-row relative items-center justify-center">
              {hasUnreadNotifications && (
                <div key="unread-dot" className="bg-seam-pink rounded-full p-[2px] absolute mt-8 z-2" />
              )}
              <NotificationsNone className={`h-6 w-6 cursor-pointer text-${textColor}`}/>
            </div>
          }
          label="Notifications"
          onClick={() => seamNavigator.navigateTo(`/notifications`)}
          isActive={navSection === "notifications" && !searchActive}
        />
      </div>
    </div>
  );
};

export default LeftBannerNav;