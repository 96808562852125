export const TomeButton = ({ onClick, containerClass, label, gradient }) => {
  return (
    <div
      onClick={onClick}
      className={containerClass}
      style={{
        backgroundImage: gradient || 'none',
      }}
    >
      {label}
    </div>
  );
};
