import mixpanel from 'mixpanel-browser';
import Parse from 'parse'
import { sendCollectPostNotification, sendSubChannelNotification } from '../utils/NotificationSendingHelpers';
import QuestManager from '../Quests/QuestManager';
import TomePostRepository, { PostType } from '../Post/TomePostRepository';

export default class TomeCollectionRepository {
  static emptyCollection(channelData: {
    creator: Parse.Object, // pointer to the Account of the creator
    name: string,
    emoji: string,
    headerImage: string,
  }) {
    const Channel = Parse.Object.extend("Channel");
    const channel = new Channel();
    const subscriberRelation = channel.relation("subscribers");
    subscriberRelation.add(channelData.creator); // all authors automatically subscribe to their own channel
    channel.set({
      creator: channelData.creator,
      name: channelData.name,
      emoji: channelData.emoji,
      headerImage: channelData.headerImage,
      subscribers: subscriberRelation,
      numSubscribers: 1,
      numPosts: 0,
      isDeleted: false,
    })
    return channel
  }

  static async createCollection(channelData: {
    creator: Parse.Object, // pointer to the Account of the creator
    name: string,
    emoji: string,
    headerImage: string,
  }): Promise<Parse.Object> {
    try {
      const channel = TomeCollectionRepository.emptyCollection(channelData);
      const newChannel = await channel.save();
      mixpanel.track("created channel");

      const followedChannelsRelation = channelData.creator.relation("followedChannels");
      followedChannelsRelation.add(newChannel);
      await channelData.creator.save();

      window.emitter.emit("SEAM_EVENT_CREATED_COLLECTION", { collection: newChannel });
      return newChannel;
    } catch (error) {
      throw error;
    }
  }

  static deleteChannel(channel: Parse.Object, onSuccess: () => void, onError: (message: any) => void) {
    // should deleting a channel also delete all the posts inside of it? No, for now.
    channel.save({ isDeleted: true }).then(() => {
      mixpanel.track("deleted channel")
      onSuccess()
    }, (error: any) => {
      onError(error)
    });
  }

  // unused
  static async subscribeToChannel(channel: Parse.Object, account: Parse.Object, onSuccess: (updatedAccount: Parse.Object) => void, onError: (message: any) => void) {
    const accountSubscriptions = account.get("followedChannels");
    accountSubscriptions.add(channel);
    const newAccount = await account.save()

    const subscriberRelation = channel.relation("subscribers");
    subscriberRelation.add(account);
    channel.increment("numSubscribers")
    channel.save().then(() => {
      mixpanel.track("subscribed to channel")
      const followedChannelsRelation = account.relation("followedChannels");
      followedChannelsRelation.add(channel);
      newAccount.save().then((finalAccount) => {
        sendSubChannelNotification(account, channel.get("creator"), channel)
        onSuccess(finalAccount)
      })
    }, (error: any) => {
      onError(error)
    });
  }

  // unused
  static async unsubscribeToChannel(channel: Parse.Object, account: Parse.Object, onSuccess: (updatedAccount: Parse.Object) => void, onError: (message: any) => void) {
    const accountSubscriptions = account.get("followedChannels");
    accountSubscriptions.remove(channel);
    const newAccount = await account.save()

    const subscriberRelation = channel.relation("subscribers");
    subscriberRelation.remove(account);
    channel.decrement("numSubscribers")
    channel.save().then(() => {
      mixpanel.track("unsubscribed to channel")
      onSuccess(newAccount)
    }, (error: any) => {
      onError(error)
    });
  }

  static collectPostToCollection(channel: Parse.Object, post: Parse.Object, collector: Parse.Object, onSuccess: (newPost: Parse.Object) => void, onError: (message: any) => void) {
    TomePostRepository.createPost({
      author: collector,
      collection: channel,
      tags: [],
      hasSpoilers: post.get("hasSpoilers"),
      text: "", //todo enable collection text
      blockData: post.get("blockData"),
      mentions: [],
      type: PostType.collect,
      aspectRatio: post.get("aspectRatio"),
      parentPost: post.get("parentPost") ?? post, // when you collect from something that was collected, keep the same parent post
    }, onSuccess, onError)
      mixpanel.track("collected post to channel")
  }

  static removePostFromChannel(post: Parse.Object) {
    TomePostRepository.deletePost(post)
  }
}