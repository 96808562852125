import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { Avatar, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import OnboardingTutorial from './OnboardingTutorial';
import { useSeamUser } from './utils/SeamUserContext';
import { IonContent, IonPage } from '@ionic/react';
import { loadFont } from './Block-SDK/src/blocks/utils/Fonts';
import { useMobile } from './utils/MobileContext';
import { useSeamNavigator } from './Navigation/SeamNavigatorContext';

export default function LandingPage() {
  const { account, isLoadingAccount, isNewUser } = useSeamUser();
  const history = useHistory();
  const {isMobile, isMobileApp, isMobileWeb} = useMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [referrer, setReferrer] = useState<Parse.Object | null>(null);
  const seamNavigator = useSeamNavigator();
  const location = useLocation();
  const [accounts, setAccounts] = useState<Parse.Object<Parse.Attributes>[]> ([]);

  // Get the referral code, and if necessary, find the name of the referrer to customize the CTA
  const params = new URLSearchParams(location.search);
  const referredBy = params.get("ref");
  const hasReferral = referredBy != null

  useEffect(() => {
    if (hasReferral) {
      const query = new Parse.Query("Account");
      query.equalTo("referralCode", referredBy);
      query.first().then((result) => {
        if (result != undefined) {
          setReferrer(result);
          setAccounts(prevAccounts => {
            const filteredAccounts = prevAccounts.filter(account => account.id !== result.id);
            return [result, ...filteredAccounts];
          });
        }
      });
    }
  }, [hasReferral, referredBy]);

  useEffect(() => {
    loadFont("Silkscreen").then(() => {
      setFontLoaded(true);
    })
  }, []);

  useEffect(() => {
    window.emitter.on('SEAM_EVENT_IS_LOADING', (loading) => {
      setIsLoading(loading);
    });

    mixpanel.track('Landing Page Viewed');
  }, []);

  useEffect(() => {
    if (account && Parse.User.current()) {
      history.replace(`/user/${account.get("profileId")}`);
    }
  }, [account, history]);

  if (isNewUser)  {
    return <OnboardingTutorial referredBy={referrer} />;
  }

  return (
    <IonPage>
      <IonContent className="hide-scrollbar" fullscreen={true} scrollY={true}>
        <div className="flex flex-col w-auto h-full items-center hide-scrollbar">
          <div className="font-silkscreen text-8xl text-center mt-8">TOME</div>
        </div>
      </IonContent>
    </IonPage>
  );
}