import { Skeleton, Typography } from "@mui/material";
import { getProcessedChannelPhotoURL } from "../components/helpers/ImageTransformers";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { useMobile } from "../utils/MobileContext";
import { useSeamUser } from "../utils/SeamUserContext";
import { Img } from "react-image";
import SeamStar from "../assets/seam-star.png";

function CollectionItem({ channel, color }) {
  const seamNavigator = useSeamNavigator();
  let backgroundImageUrl = getProcessedChannelPhotoURL(channel?.get("headerImage"));

  const CollectionNumPostsLabel = () => {
    const numPosts = channel?.get("numPosts");
    return (
      <h3 className={`my-0 w-[120px] text-center text-${color} text-opacity-50`}>
        {numPosts} post{numPosts !== 1 && 's'}
      </h3>
    )
  }

  return (
    <div onClick={() => seamNavigator.navigateTo(`/collection/${channel.id}`, channel)} className="flex flex-col items-center justify-start w-auto px-2 space-y-2 cursor-pointer">
      <h2 className={`text-${color} my-0 w-[120px] text-center truncate`}>
        {channel.get("name")}
      </h2>
      <Img 
        src={backgroundImageUrl ? ([backgroundImageUrl, channel?.get("headerImage")]) : (SeamStar)}
        loader={<Skeleton variant="rectangular" className="rounded-[12px] relative" style={{height: 152, width: 120}} />}
        className="w-[120px] h-[152px] rounded-[12px] relative"
      />
      <CollectionNumPostsLabel />
    </div>
  );
}

export default CollectionItem;