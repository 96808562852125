import React from 'react';
import { IonTabs, IonTabBar, IonTabButton } from '@ionic/react';
import { useLogin } from '@privy-io/react-auth';
import SeamAuthenticator from '../Authentication/SeamAuthenticator';
import { Typography } from '@mui/material';

const FloatingSignInButton = ({ children, mobileTabsVisible, tabBarOpacity, isMobile }) => {
  const { login } = useLogin({
    onComplete: (user, isNewUser, wasAlreadyAuthenticated) => {
      // Handle login through SeamAuthenticator and emit global login success event
      SeamAuthenticator.handlePrivyLogin(user, isNewUser).then(() => {
      });
    },
    onError: (error) => {
      console.log(error);
    }
  });

  return (
    <IonTabs className="w-auto" style={{ marginTop: "env(safe-area-inset-top)" }}>
      {children}
      <IonTabBar
        slot="bottom"
        style={{
          visibility: mobileTabsVisible ? "visible" : "hidden",
          paddingBlock: '4px',
          paddingInline: '2px',
          borderRadius: '64px',
          opacity: tabBarOpacity,
          transition: 'opacity 0.3s ease',
        }}
        className={`flex flex-row items-center justify-center ${!isMobile && 'ml-[25rem]'} h-[70px] w-[90px]`}
      >
        <IonTabButton className={`w-[90px] h-[70px]`}>
          <div
            onClick={(e) => {
              e.preventDefault();
              login();
            }}
            className="relative flex flex-row items-center rounded-[64px] bg-seam-pink h-[70px] w-[90px] justify-center"
          >
            <Typography variant="h4" className="text-white">Sign in</Typography>
          </div>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default FloatingSignInButton;