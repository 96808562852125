import { useContext } from "react";
import { useState } from "react";
import { Popover, List, ListItem, ListItemText } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import { SeamUserContext, useSeamUser } from "../utils/SeamUserContext";
import TomePostRepository from "../Post/TomePostRepository";
import mixpanel from "mixpanel-browser";
import { Clipboard } from "@capacitor/clipboard";

export default function FeedItemOptions(props) {
  const { post, isOnChannelPage, color } = props
  const { account } = useSeamUser();
  const { isAdmin } = useContext(SeamUserContext);
  const isSelfPost = post?.get("author")?.id === account?.id
  const canDelete = isSelfPost
  const author = post?.get("author")
  const [anchorEl, setAnchorEl] = useState(null);
  const [postReported, setPostReported] = useState(false);
  const isSettingsOpen = Boolean(anchorEl);

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = async () => {
    const objectId = post.id;
    const url = `https://tomebooks.com/post/${objectId}`;

    try {
      await Clipboard.write({
        string: url
      });
      alert("Link copied to clipboard!");
      mixpanel.track("share via link", { 'url': url });
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
    handlePopOverClose();
  };

  const postOptionsPopover = () => {
    return (
      <Popover
        id={"settings"}
        open={isSettingsOpen}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <List>
        {!isSelfPost && <ListItem
          button
          key={"flag"}
          onClick={async () => {
            setPostReported(true);
            try {
              mixpanel.track("post_reported")
              await TomePostRepository.flag(account?.get("userId"), post)
            } catch (error) {
              Sentry.captureException(error)
            }
            window.alert("Thanks for reporting -- we'll take a look!")
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: {color: "red"} }}
            primary={"Report"}
          />
        </ListItem>}
        {canDelete && <ListItem
          button
          key={"delete"}
          onClick={async () => {
            try {
              await TomePostRepository.deletePost(post)
            } catch (error) {
              console.log(error)
              Sentry.captureException(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: { color: "red" }}}
            primary={"Delete"}
          />
        </ListItem>}
        {!isSelfPost && <ListItem
          button
          key={"block"}
          onClick={async () => {
            if (window.confirm("Are you sure you want to block " + author?.get("profileId") + "? \n\n You won't see their posts anymore.")) {
              try {
                mixpanel.track("user_blocked")
                account.addUnique("blockedUsers", author)
                account.save()
                  .then((updatedAccount) => {
                    // The account was successfully updated
                    window.emitter.emit("SEAM_EVENT_DELETE_POST", post.id)
                    alert(author?.get("profileId") + " has been blocked.")
                  }, (error) => {
                    // The save failed. Error is an instance of Parse.Error.
                    console.error('Error while adding blocked user:', error);
                  });
              } catch (error) {
                console.log(error)
                Sentry.captureException(error)
              }
              handlePopOverClose()
            }
          }}>
          <ListItemText
            classes={{ primary: { color: "red" }}}
            primary={"Block User"}
          />
        </ListItem>}
        {isAdmin && <ListItem
          button
          key={"admin-delete"}
          onClick={async () => {
            try {
              TomePostRepository.deletePost(post);
            } catch (error) {
              console.log(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: { color: "red" }}}
            primary={"[ADMIN]: DELETE"}
          />
        </ListItem>}
        {isAdmin && <ListItem
          button
          key={"admin-hide"}
          onClick={async () => {
            try {
              TomePostRepository.hidePost(post);
            } catch (error) {
              console.log(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: { color: "red" }}}
            primary={"[ADMIN]: HIDE"}
          />
        </ListItem>}
        {isAdmin && <ListItem
          button
          key={"admin-nsfw"}
          onClick={async () => {
            try {
              TomePostRepository.addNSFWTag(post);
            } catch (error) {
              console.log(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: { color: "red" }}}
            primary={"[ADMIN]: ADD NSFW TAG"}
          />
        </ListItem>}
      </List>
      </Popover >
    );
}

const optionsPopover = postOptionsPopover()

return (
  <>
    <div className={`flex items-center justify-center ${isOnChannelPage && `bg-transparent`}`} style={{ marginLeft: "auto" }} onClick={(event) => {
      if (!account) {event.preventDefault()}
      else setAnchorEl(event.currentTarget);
    }}>
      <MoreHoriz className={`text-${color} h-4 w-4`}/>
    </div>
    {optionsPopover}
  </>
)
}