import React, { useState } from "react";
import { Button, CircularProgress, Box } from "@mui/material";
import { FirebaseStorage } from "@capacitor-firebase/storage";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import { nanoid } from "nanoid";
import { useSeamUser } from "../utils/SeamUserContext";
import { useMobile } from "../utils/MobileContext";

const ImageUploadComponent = ({
  fileTypes,
  basePath = "profilePhotos",
  onUpdate,
  backgroundImageUrl,
  fileName,
}) => {
  const [progressPercent, setProgressPercent] = useState(0);
  const { isMobileApp } = useMobile();
  let types = fileTypes === "" ? "image/*" : fileTypes;

  const onFinish = async (files) => {
    const file = files[0];
    if (!file) {
      console.log("no file selected");
      return;
    }
    const path = `${basePath}/${fileName ? fileName : nanoid()}`;
    await FirebaseStorage.uploadFile(
      {
        path: path,
        blob: file.blob,
        uri: file.path,
      },
      async (event, error) => {
        if (error) {
          return;
        }
        if (event) {
          setProgressPercent((event.progress * 100).toFixed());
        }

        if (event && event.completed) {
          const { downloadUrl } = await FirebaseStorage.getDownloadUrl({
            path: path,
          });
          onUpdate(downloadUrl);
          // Reset progressPercent to 0 after a short delay to allow the user to see it reached 100%
          setTimeout(() => setProgressPercent(0), 500); 
        }
      }
    );
  };

  return (
    <Box className="relative flex items-center w-full h-full justify-center border-2 border-gray-400/20">
      <Button
        component="label"
        className={`border-2 border-gray-400/20 w-full h-full`}
        disabled={progressPercent !== 0 && progressPercent !== 100} // Allow clicking again once it hits 100%
        onClick={async (e) => {
          setProgressPercent(1); // Just to initiate the process, it will be overridden
          e.preventDefault();
          try {
            const result = await FilePicker.pickImages({
              multiple: false,
              readData: true,
            });
            if (result) {
              onFinish(result.files);
            }
          } catch (error) {
            console.error("Error picking file: ", error);
            setProgressPercent(0); // Ensure progress is reset if there's an error
          }
        }}
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
          textTransform: "none",
          justifyContent: "center", // Ensure vertical centering
          gap: "2px",
          padding: 0,
          backgroundColor: "#FFF", // Default white background, replace with CSS variable if needed
          "&:hover": {
            backgroundColor: "#f0f0f0", // Slightly darker shade on hover, adjust as needed
          },
          "&.Mui-disabled": {
            backgroundColor: "#FFF",
            opacity: 0.5,
          },
          backgroundImage: backgroundImageUrl
            ? `url(${backgroundImageUrl})`
            : "none",
          backgroundSize: "cover", // Ensure the background image covers the button area
          backgroundPosition: "center", // Center the background image
        }}
      >
        <span
          className="absolute text-white bg-black/50 py-1 px-3 rounded-full"
          style={{ fontSize: "0.75rem" }}
        >
          EDIT
        </span>
      </Button>
      <CircularProgress
        variant="determinate"
        value={progressPercent}
        size={72} // Adjust to fit the button size
        thickness={2} // Adjust thickness of the ring
        style={{
          position: "absolute",
          zIndex: 0,
          color: "#EE39FB",
          pointerEvents: "none", // Ignore pointer events
        }}
        color="inherit"
      />
    </Box>
  );
};

export default ImageUploadComponent;
