import React, { useState, useEffect } from "react";
import SeamCommentRepository from "./SeamCommentRepository";
import SeamComment from "./SeamComment";
import Parse from "parse";
import { useHistory, useParams } from "react-router-dom";
import Header from "../Navigation/Header";
import { IonPage, IonContent, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import CommentComposer from "../Discover/CommentComposer";
import { useSeamUser } from "../utils/SeamUserContext";
import { sendCommentNotification } from "../utils/NotificationSendingHelpers";
import { Virtuoso } from "react-virtuoso";
import { useMobile } from "../utils/MobileContext";
import DesktopSidebarWrapper from "../components/DesktopSidebarWrapper";
import BlockFactory from "../Block-SDK/src/blocks/BlockFactory";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { ChevronLeft } from "@mui/icons-material";

const CommentDetailPage = ({ }) => {
  const { commentId } = useParams();
  const { contextObject } = useSeamNavigator();
  const [comment, setComment] = useState(contextObject && contextObject.id == commentId ? contextObject : undefined);
  const [replies, setReplies] = useState([]);
  const [optimisticReplies, setOptimisticReplies] = useState([]);
  const [isActivePage, setIsActivePage] = useState(true);
  const [hasMoreReplies, setHasMoreReplies] = useState(true);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const REPLIES_PER_PAGE = 5;
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();

  useEffect(() => {
    if (!comment) {
      fetchComment();
    }
  }, [commentId]);

  useEffect(() => {
    if (comment) {
      loadMoreReplies();
    }
  }, [comment]);

  const fetchComment = async () => {
    if (!commentId) {
      return;
    }
    const fetchedComment = await SeamCommentRepository.getComment(commentId);
    if (!fetchedComment) {
      return;
    }
    setComment(fetchedComment)
  };

  useIonViewWillEnter(() => {
    setIsActivePage(true);
  });

  useIonViewWillLeave(() => {
    setIsActivePage(false);
  });

  useEffect(() => {
    const handleNewReply = (event) => {
      // Ensure the new reply is for the current comment detail being viewed
      if (event.comment.get("parentComment").id === commentId) {
        // Directly update replies to trigger re-render, ensuring no duplicates
        setReplies((prevReplies) => {
          const existing = prevReplies.find(
            (reply) => reply.id === event.comment.id
          );
          if (!existing) {
            return [event.comment, ...prevReplies];
          }
          return prevReplies;
        });
      }
    };

    window.emitter.on("SEAM_EVENT_COMMENT_ADDED", handleNewReply);

    return () => window.emitter.off("SEAM_EVENT_COMMENT_ADDED", handleNewReply);
  }, [commentId]);

  const handleAddReply = async (replyText, mentionees, miniappData) => {
    const onSuccess = (newReply) => {
      if (!comment || !account) return;

      sendCommentNotification(account, comment.get("author"), comment.get("parentPost").id, replyText);
      window.emitter.emit("SEAM_EVENT_COMMENT_ADDED", {
        comment: newReply,
        isReply: true,
      });
    };

    const onError = (error) => {
      console.error("Unable to comment: " + error);
    };

    SeamCommentRepository.createComment(
      {
        author: account,
        parentPost: comment.get("parentPost"),
        parentComment: comment, // This ensures the new comment is treated as a reply
        text: replyText,
        miniappData: miniappData
      },
      onSuccess,
      onError
    );
  };

  const loadMoreReplies = async () => {
    if (!hasMoreReplies || !comment || loadingReplies) {
      return;
    }
    setLoadingReplies(true);
    try {
      const query = new Parse.Query("Comment");
      query.equalTo(
        "parentComment",
        Parse.Object.extend("Comment").createWithoutData(commentId)
      );
      query.include("author");
      query.include("parentPost");
      query.limit(REPLIES_PER_PAGE);
      query.skip(replies.length);
      query.ascending("createdAt");
  
      const replyResults = await query.find();
      setReplies((prevReplies) => [...prevReplies, ...replyResults]);
      if (replyResults.length < REPLIES_PER_PAGE) {
        setHasMoreReplies(false);
      }
    } catch (error) {
      console.error("Failed to load replies:", error);
    } finally {
      setLoadingReplies(false);
    }
  };

  const leftAction = isMobileApp ? () => { } : () => history.go(-1)

  const leftIcon = () => {
    return (
      <div onClick={leftAction}>
        <ChevronLeft className="text-black py-2 px-6 cursor-pointer" />
      </div>
    )
  }

  const commentAuthor = comment?.get("author");
  const commentAuthorName = commentAuthor?.get("profileId");
  const commentData = comment?.get("miniappData");
  const miniappData = commentData?.data;
  const miniappName = commentData?.type ? BlockFactory.getPrintableBlockName(commentData?.type) : "Unknown";
  
  // for future snapshotting of comments (if needed): 
  // const seoTitle = miniappData.text ? miniappData.text : `${commentAuthorName}'s comment on Seam`;
  // const seoImage = ``;
  // const seoDescription = miniappName === "Unknown" ? "Create with miniapps on Seam" : `Use the ${miniappName} miniapp on Seam`;

  return (
    <IonPage id={"comment " + commentId}>
      <Header
        leftIcon={isMobileApp && leftIcon()}
        rightIcon={null}
        leftAction={leftAction}
        rightAction={() => { }}
      />
      <IonContent fullscreen={true} scrollY={false} scrollX={false}>
        <DesktopSidebarWrapper showDesktopHeader={true} leftIcon={!isMobileApp && leftIcon()} rightIcon={!isMobileApp && null}>
          <div className="flex flex-row justify-center w-full h-full">
            <div className={`w-full max-w-[720px] justify-center items-center ${!isMobileApp && "mt-6"}`}>
              {comment && commentAuthor && (
                <Virtuoso
                  className={`ion-content-scroll-host hide-scrollbar`}
                  data={replies}
                  endReached={loadMoreReplies}
                  overscan={3}
                  itemContent={(index, reply) => {
                    return (
                      <div key={reply.id} className="w-full m-auto pt-4 max-w-full">
                        <SeamComment
                          comment={reply}
                          post={comment?.get("parentPost")}
                        />
                      </div>
                    );
                  }}
                  components={{
                    Header: () => {
                      return (
                        <div className="w-full max-w-full rounded-[20px] border border-gray-200 bg-[#FCFCFC] shadow-none">
                          <SeamComment
                            comment={comment}
                            post={comment?.get("parentPost")}
                            isOnCommentDetailPage={true}
                          />
                        </div>
                      );
                    },
                    Footer: () => {
                      return (
                        <div className="w-auto h-[222px]"></div> // allow scrolling under the comment composer
                      );
                    },
                  }}
                />
              )}
              {account && <CommentComposer onSubmit={handleAddReply} isActivePage={isActivePage} />}
            </div>
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default CommentDetailPage;
