import React, { useState, ChangeEvent, FormEvent } from "react";
import { Button, TextField, IconButton, Box } from "@mui/material";
import { useSeamUser } from "../utils/SeamUserContext";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

interface BlockedTagsPageProps {
  closeModal: () => void;
}

const BlockedTagsPage: React.FC<BlockedTagsPageProps> = ({ closeModal }) => {
  const { account } = useSeamUser();
  const [tags, setTags] = useState<string[]>(account?.get("blockedTags") ?? ["nsfw"]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleAddTag = () => {
    setTags([...tags, ""]);
    setErrors([...errors, ""]);
  };

  const handleRemoveTag = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
    setErrors(errors.filter((_, i) => i !== index));
  };

  const handleTagChange = (index: number, value: string) => {
    const updatedTags = [...tags];
    updatedTags[index] = value.toLowerCase();
    setTags(updatedTags);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors = tags.map(tag => (tag === "" ? "No empty tags!" : ""));
    setErrors(newErrors);

    if (newErrors.every(error => error === "")) {
      account?.set("blockedTags", tags);
      await account?.save();
      closeModal();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center">
      {tags.map((tag, index) => (
        <Box key={index} className="flex items-center mb-4 w-full">
          <TextField
            value={tag}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleTagChange(index, e.target.value)}
            placeholder="Tag"
            className="w-64"
            inputProps={{ style: { textTransform: "lowercase" } }}
            error={!!errors[index]}
            helperText={errors[index]}
          />
          <IconButton onClick={() => handleRemoveTag(index)}>
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        type="button"
        onClick={handleAddTag}
        className="w-full flex items-center justify-center mb-4 py-2 bg-gray-200 hover:bg-gray-300"
      >
        <AddCircleOutline />
        <span className="ml-2">Add tag</span>
      </Button>
      <Button
        type="submit"
        className="w-full bg-seam-blue min-h-[48px] rounded-[8px] text-center"
      >
        <h3 className="text-seam-white">Save blocked tags</h3>
      </Button>
    </form>
  );
};

export default BlockedTagsPage;