import { useEffect, useState } from "react";
import { followAccount, unfollowAccount } from "../utils/FollowAccountHelpers";
import { useSeamUser } from "../utils/SeamUserContext";
import { TomeButton } from "../components/TomeButton";

const ProfilePageFollowButton = ({ creatorAccount, color, defaultBackground }) => {
  const { account, refreshAccount } = useSeamUser();
  const [isFollowing, setIsFollowing] = useState(undefined);

  useEffect(() => {
    if (!account || isFollowing != undefined) { return }
    const fetchFollowingStatus = async () => {
      const relation = account.relation("followed");
      const query = relation.query();
      query.equalTo("objectId", creatorAccount.id);
      const following = await query.first();
      setIsFollowing(!!following);
    }
    fetchFollowingStatus();
  }, [account]);

  const tapFollowButton = () => {
    if (isFollowing) {
      unfollowAccount(account, creatorAccount, refreshAccount);
    } else {
      followAccount(account, creatorAccount, refreshAccount);
    }
    setIsFollowing(!isFollowing);
  }

  const containerClass = `
    ${defaultBackground ? `bg-${defaultBackground}` : `bg-${color}`}
    ${color === 'white' ? 'border-neutral-50/50' : 'border-neutral-950/50'}
    border bg-opacity-20 w-full h-[38px] rounded-[24px] flex items-center justify-center
  `.trim();

  return (
    <TomeButton
      onClick={tapFollowButton}
      containerClass={containerClass}
      label={<h4 className="uppercase my-0">{isFollowing ? "Following" : "Follow"}</h4>}
    />
  )
}

export default ProfilePageFollowButton;