import mixpanel from 'mixpanel-browser';
import Parse from 'parse'
import { sendMentionNotification, sendPostLikeNotification } from '../utils/NotificationSendingHelpers';
import QuestManager from '../Quests/QuestManager';

export enum PostType {
  post = "POST",
  collect = "COLLECT",
}

export default class TomePostRepository {
  static reactionCache: { [key: string]: Parse.Object | null } = {};

  static createPost(data: {
    author: Parse.Object,
    collection: Parse.Object,
    tags: string[],
    hasSpoilers: boolean,
    text: string,
    blockData: any,
    mentions: any[],
    type: PostType,
    aspectRatio: number | undefined,
    parentPost: Parse.Object | undefined,
  },
    onSuccess: (newPost: Parse.Object) => void, onError: (message: any) => void) {

    const postData = {
      text: data.text,
      blockData: data.blockData
    }

    const Post = Parse.Object.extend("Post");
    const post = new Post();

    post.save({
      author: data.author,
      postData: postData,
      tags: data.tags,
      hasSpoilers: data.hasSpoilers,
      commentCount: 0,
      reactionCount: 0,
      reported: [],
      isHidden: false,
      isDeleted: false,
      aspectRatio: data.aspectRatio,
      dataType: data.type,
      collection: data.collection,
      parentPost: data.parentPost,
    }).then(async (newPost: Parse.Object) => {
      mixpanel.track("published post")
      data.collection.increment("numPosts")
      data.collection.save().then(() => {
        QuestManager.completeStreakPost(data.author);
        onSuccess(newPost)
      }, (error: any) => {
        onError(error)
      })
    }, (error: any) => {
      onError(error)
    });
  }

  static getPost(postId: string) {
    const query = new Parse.Query("Post");
    query.equalTo("objectId", postId);
    query.include("author");
    return query.first();
  }

  static async getReaction(post: Parse.Object, account: Parse.Object) {
    const cacheKey = `${post.id}`;

    if (this.reactionCache[cacheKey] != undefined || this.reactionCache[cacheKey] === null) {
      return this.reactionCache[cacheKey];
    }

    const reactions = post.relation('reactions');
    const query = reactions.query();
    query.equalTo('reactor', account);

    const existingReaction = await query.first();

    // Store the reaction in the cache
    this.reactionCache[cacheKey] = existingReaction ?? null

    return existingReaction;
  }

  static reactToPost(post: Parse.Object,
    reactionKey: string,
    reactor: Parse.Object,
    currentReaction: Parse.Object | undefined,
    onSuccess: (newReaction: Parse.Object | undefined) => void, onError: (message: any) => void) {
    if (currentReaction && typeof currentReaction.destroy === "function") {
      TomePostRepository.removeReaction(post, currentReaction, onSuccess)
    } else {
      TomePostRepository.addReaction(post, reactionKey, reactor, onSuccess)
    }
  }

  private static addReaction(post: Parse.Object, reactionKey: string, reactor: Parse.Object, onSuccess: (newReaction: Parse.Object | undefined) => void) {
    const Reaction = Parse.Object.extend('PostReaction');
    const newReaction = new Reaction()
    newReaction.set("post", post)
    newReaction.set("reactionKey", reactionKey)
    newReaction.set("reactor", reactor)
    newReaction.save().then(async (savedReaction: Parse.Object) => {
      const reactionRelation = post.relation("reactions")
      reactionRelation.add(savedReaction)
      post.increment("reactionCount")
      post.save();
      this.reactionCache[`${post.id}`] = savedReaction;
      const isOwnAccount = reactor == post.get("author");
      !isOwnAccount && sendPostLikeNotification(reactor, post.get("author"), post.id)
      mixpanel.track("Reactions", { "reaction": reactionKey });
      onSuccess(savedReaction)
    }, (error: any) => {
      console.error(error)
    });
  }

  private static removeReaction(post: Parse.Object, existingReaction: Parse.Object, onSuccess: (newReaction: Parse.Object | undefined) => void) {
    existingReaction.destroy().then((deleted) => {
      delete this.reactionCache[`${post.id}`];
      post.decrement("reactionCount")
      post.save()
      onSuccess(undefined)
    }, (error) => {
      console.error(error)
    });
  }

  static deletePost(post: Parse.Object) {
    post.set("isDeleted", true)
    post.save();
    window.emitter.emit("SEAM_EVENT_DELETE_POST", post.id)

    const collection = post.get("collection");
    collection.increment("numPosts", -1);
  }

  static hidePost(post: Parse.Object) {
    post.set("isHidden", true)
    post.save();
    window.emitter.emit("SEAM_EVENT_DELETE_POST", post.id)
  }

  static addNSFWTag(post: Parse.Object) {
    post.addUnique("tags", "nsfw")
    post.save();

    const nsfwTagQuery = new Parse.Query("Tags");
    nsfwTagQuery.equalTo("name", "nsfw");
    nsfwTagQuery.first().then((tag) => {
      if (tag != undefined) {
        const tagRelation = tag.relation("posts");
        tagRelation.add(post);
        tag.increment("tagCount", 1)
        tag.save();
      }
    })
  }

  static async setHasSpoilers(post: Parse.Object, hasSpoilers: boolean): Promise<void> {
    try {
      post.set("hasSpoilers", hasSpoilers);
      await post.save();
    } catch (error) {
      console.error(`Failed to update hasSpoilers for post ${post.id}:`, error);
      throw error;
    }
  }

  static async flag(myUserId: string, post: Parse.Object) {
    try {
      let existingReports = post.get("reported")
      existingReports.push(myUserId)
      post.set("reported", existingReports)
      post.save();

      const subject = 'Seam: Post Reported';
      const text = `Post with ID ${post.id} has been reported by user ${myUserId}. Link: https://tomebooks.com/post/${post.id}`;

      await Parse.Cloud.run('sendEmail', { subject, text });
    } catch (error) {
      console.error('Error reporting post:', error);
      throw error;
    }
  }
}