import React, { useState, useRef, useEffect } from "react";
import { TomeButton } from "../components/TomeButton";
import { CloseRounded } from "@mui/icons-material";
import { IOSSwitch } from "../components/IOSSwitch";

// Main Controller Component
type TagInputRowProps = {
  tags: string[];
  onTagsChange: (updatedTags: string[]) => void;
  hasSpoilers: boolean;
  setHasSpoilers: (hasSpoilers: boolean) => void;
};

const TagInputRow: React.FC<TagInputRowProps> = ({ tags, onTagsChange, hasSpoilers, setHasSpoilers }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  // Dynamically set input width based on text
  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      const textWidth = spanRef.current.offsetWidth;
      inputRef.current.style.width = `${Math.max(textWidth, 73)}px`; // 73 min to prevent truncation of 72 width placeholder
    }
  }, [inputValue]);

  const addTag = (newTag: string) => {
    const upperCaseTag = newTag.toUpperCase();
    if (upperCaseTag === "SPOILERS") {
      setHasSpoilers(true);
      return; // Do not add as a tag
    }
    if (!tags.includes(upperCaseTag)) {
      const updatedTags = [...tags, upperCaseTag];
      onTagsChange(updatedTags);
    }
  };

  const removeTag = (tagToDelete: string) => {
    const updatedTags = tags.filter((tag) => tag !== tagToDelete);
    onTagsChange(updatedTags);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      addTag(inputValue.trim());
      setInputValue("");
      return;
    }

    if (e.key === "Backspace" && inputValue === "" && tags.length > 0) {
      removeTag(tags[tags.length - 1]);
    }
  };

  const handleSpoilerToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasSpoilers(event.target.checked);
  };

  return (
    <div className="flex items-center justify-between w-full h-[64px] space-x-4">
      <TagInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleKeyDown={handleKeyDown}
        inputRef={inputRef}
        spanRef={spanRef}
      />
      <TagList tags={tags} removeTag={removeTag} />
      <SpoilerSwitch hasSpoilers={hasSpoilers} handleSpoilerToggle={handleSpoilerToggle} />
    </div>
  );
};

export default TagInputRow;

type TagInputProps = {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  spanRef: React.RefObject<HTMLSpanElement>;
};

const TagInput: React.FC<TagInputProps> = ({ inputValue, setInputValue, handleKeyDown, inputRef, spanRef }) => (
  <div
    className="flex-shrink-0 bg-tome-gray px-3 py-1 flex items-center"
    style={{ height: "32px", maxWidth: "50%" }} // max width to truncate and leave space for tag h scroll
  >
    <input
      ref={inputRef}
      type="text"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder="# ADD A TAG"
      className="bg-transparent text-star w-auto max-w-full truncate outline-none text-[10px] font-bold font-sans leading-[10px] tracking-[1px] text-gray-600 placeholder-gray-500"
    />
    <span
      ref={spanRef}
      className="invisible w-auto absolute whitespace-pre text-[10px] font-bold font-sans leading-[10px] tracking-[1px]"
    >
      {inputValue || "# ADD A TAG"}
    </span>
  </div>
);

type TagListProps = {
  tags: string[];
  removeTag: (tag: string) => void;
};

const TagList: React.FC<TagListProps> = ({ tags, removeTag }) => (
  <div className="flex-1 flex items-center space-x-2 overflow-x-auto hide-scrollbar">
    {tags.map((tag, index) => (
      <div
        key={index}
        className="flex items-center space-x-2 justify-between bg-black text-white p-2 flex-shrink-0"
      >
        <h4 className="my-0 uppercase truncate whitespace-nowrap">{`#${tag}`}</h4>
        <TomeButton
          onClick={() => removeTag(tag)}
          containerClass={"flex items-center justify-center h-auto text-white"}
          label={<CloseRounded style={{ fontSize: "14px" }} />}
          gradient={undefined}
        />
      </div>
    ))}
  </div>
);

type SpoilerSwitchProps = {
  hasSpoilers: boolean;
  handleSpoilerToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SpoilerSwitch: React.FC<SpoilerSwitchProps> = ({ hasSpoilers, handleSpoilerToggle }) => (
  <div className="flex-shrink-0 flex items-center space-x-2">
    <h4 className="my-0 text-pink-500">SPOILERS</h4>
    <IOSSwitch checked={hasSpoilers} onChange={handleSpoilerToggle} />
  </div>
);