import { ChevronLeftRounded, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import TomeHeaderBar from "../Block-SDK/src/components/TomeHeaderBar";
import { TomeButton } from "../components/TomeButton";
import { useSeamUser } from "../utils/SeamUserContext";
import SeamStar from "../assets/seam-star.png";
import { getProcessedChannelPhotoURL } from "../components/helpers/ImageTransformers";
import { useState } from "react";

type ChannelSelectionPageProps = {
  channel: any,
  changeSelectedChannel: (newChannel: any) => void;
}

const ChannelSelectionPage: React.FC<ChannelSelectionPageProps> = ({
  channel,
  changeSelectedChannel,
}) => {
  const { userCreatedChannels } = useSeamUser();
  const [selectedChannel, setSelectedChannel] = useState(channel);

  const ChannelSelectionItem: React.FC<{ channel: Parse.Object; isSelected: boolean; }> = ({ channel, isSelected }) => {
    const channelPhoto = getProcessedChannelPhotoURL(channel.get("headerImage"));
    return (
      <div
        className="flex flex-row items-center justify-between w-full h-auto py-2 cursor-pointer"
        onClick={() => setSelectedChannel(channel)}
      >
        <div className="flex flex-row w-full space-x-4 items-center truncate">
          <img
            src={channelPhoto || SeamStar}
            className="w-[60px] h-[96px] rounded-[8px]"
            alt={`${channel.get("name")} logo`}
          />
          <div className="flex flex-col space-y-2">
            <h2 className="tome-text-gradient my-0 w-full truncate">
              {channel.get("name")}
            </h2>
            <h3 className="tome-text-gradient my-0">{channel.get("numPosts")} Posts</h3>
          </div>
        </div>
        <div className="h-full w-auto flex items-center justify-center">
          {isSelected ? (
            <RadioButtonChecked className="text-tome-pink-gradient-end" />
          ) : (
            <RadioButtonUnchecked />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <TomeHeaderBar
        leftComponent={
          <TomeButton
            onClick={() => changeSelectedChannel(selectedChannel)}
            containerClass={'p-[6px] flex items-center justify-center rounded-full border border-opacity-10'}
            label={<ChevronLeftRounded style={{ fontSize: '24px' }} />}
            gradient={undefined}
          />
        }
        centerComponent={<h3 className="my-0">Collection:</h3>}
        rightComponent={<></>}
      />
      <div className="px-4 flex flex-col space-y-4 items-center justify-start">
        {userCreatedChannels.map((channel) => (
          <ChannelSelectionItem
            key={channel.id}
            channel={channel}
            isSelected={selectedChannel.id === channel.id}
          />
        ))}
      </div>
    </div>
  )
}

export default ChannelSelectionPage;