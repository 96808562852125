import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();

    useEffect(() => {
        const listener = App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            const url = new URL(event.url);
            const path = url.pathname; // Get the path from the URL
            if (path) {
                history.push(path);
            }
        });
    }, [history]);
    
    return null;
};

export default AppUrlListener;