import React, { useRef, forwardRef, useMemo } from 'react';
import Parse from 'parse';
import { useSeamUser } from '../utils/SeamUserContext';
import Feed from '../components/Feed';
import ProfilePageHeader from './ProfilePageHeader';
import { Divider } from '@mui/material';
import CollectionsCarousel from './CollectionsCarousel';
import { useMobile } from '../utils/MobileContext';

const ProfileFeed = forwardRef(({
  creatorAccount,
  profileCustomizations,
  isSelfProfile,
  color,
  defaultBackground,
  takingScreenshot,
  ...props
}, ref) => {
  const { isMobile, isMobileApp } = useMobile();
  const feedRef = useRef();

  React.useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (feedRef.current) {
        feedRef.current.scrollToTop();
      }
    },
  }));

  const profileQuery = useMemo(() => {
    let query = new Parse.Query('Post');
    query.equalTo("author", creatorAccount)
    query.include(['parentPost', 'parentPost.author', 'author']);
    query.descending('createdAt');
    return query;
  }, [creatorAccount]);

  const ProfileFeedDivider = () => {
    return (
      <div className="w-full h-auto pt-6 pb-4 px-4">
        <Divider className={`w-auto h-[1px] bg-${color} bg-opacity-10`} />
      </div>
    )
  }

  const Header = () => (
    <div className={`w-full h-full flex flex-col items-start justify-start hide-scrollbar overflow-y-auto ${!isMobile && 'pt-6 max-w-[720px]'}`}>
      <ProfilePageHeader
        creatorAccount={creatorAccount}
        profileCustomizations={profileCustomizations}
        isSelfProfile={isSelfProfile}
        color={color}
        defaultBackground={defaultBackground}
        takingScreenshot={takingScreenshot}
      />
      <ProfileFeedDivider />
      <CollectionsCarousel
        creatorAccount={creatorAccount}
        profileCustomizations={profileCustomizations}
        isSelfProfile={isSelfProfile}
        color={color}
      />
      <ProfileFeedDivider />
      <h2 className={`text-${color} mt-2 mb-8 w-full text-center`}>
        {isSelfProfile ? 'My' : `${creatorAccount.get('profileId')}'s`} posts
      </h2>
    </div>
  );

  return (
    <Feed
      ref={feedRef}
      postQuery={profileQuery}
      Header={Header}
      dependencies={[]}
      isOnChannelPage={false}
      color={color}
      defaultBackground={defaultBackground}
    />
  );
});

export default ProfileFeed;