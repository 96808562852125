import Parse from 'parse';
import LoadingComponent from "./components/LoadingComponent";
import HomePage from "./Routes/HomePage";
import { Redirect } from "react-router-dom"
import { useSeamUser } from "./utils/SeamUserContext";
import SearchPage from "./Discover/SearchPage";
import NotificationsPage from "./NotificationsPage";
import ErrorPage from "./ErrorPage";
import { useMobile } from './utils/MobileContext';
import { Seo } from './utils/Seo';
import LandingPage from './LandingPage';

export enum SeamPageType {
  Home,
  Quests,
  Search,
  Notifications,
  Landing
}

interface InternalPageProps {
  type: SeamPageType;
}

// Protects Internal pages from unauthorized users, making them sign in or complete onboarding
// Hosts the header so we only have to implement that in one place
export default function InternalPage(
  { type }: InternalPageProps
) {
  const isAuthenticated = Parse.User.current() != undefined
  const { account, isLoadingAccount } = useSeamUser();
  const { isMobileApp } = useMobile();

  if (account == undefined && isLoadingAccount) {
    return <LoadingComponent />
  }

  if (!isAuthenticated || account == undefined) {
    if (isMobileApp) {
      return <Redirect exact path="/" to={account != undefined ? "/homeTab" : "/login"} />
    }
    return <LandingPage />
  }

  const pageForType = () => {
    switch (type) {
      case SeamPageType.Landing: { return isMobileApp ? <Redirect exact path="/" to={account != undefined ? "/homeTab" : "/login"} /> : <Redirect exact path="/" to={account != undefined ? `/home` : "/login"} /> }
      case SeamPageType.Home: return <HomePage />
      case SeamPageType.Search: return <SearchPage />
      case SeamPageType.Notifications: return <NotificationsPage />
      default: return <ErrorPage />
    }
  }

  return (
    <>
      <Seo
        title={"Tome"}
        description={"Fantasy books & reading community"}
        author={"@tome-app"}
        imageURL={"https://www.tome.universe/seam-logo.png"}
        url={"https://www.tome.universe/"}
      />
      {pageForType()}
    </>
  );
}