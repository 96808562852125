import React, { memo, useState, useEffect, useRef } from 'react';
import TomePostRepository from '../../Post/TomePostRepository';
import mixpanel from 'mixpanel-browser';
import { FavoriteOutlined, FavoriteBorder, LibraryAddOutlined, ChatBubbleOutlineRounded } from '@mui/icons-material';
import { debounce } from 'lodash';
import { ReactComponent as StitchIcon } from "../../assets/icons/stitchIcon.svg"
import BlockFactory from '../../Block-SDK/src/blocks/BlockFactory';
import SeamPillButton from '../../Block-SDK/src/components/SeamPillButton';
import { useSeamUser } from '../../utils/SeamUserContext';
import { useSeamNavigator } from '../../Navigation/SeamNavigatorContext';
import { IonContent, IonModal } from '@ionic/react';
import { useMobile } from '../../utils/MobileContext';
import SeamShareBottomSheet from './SeamShareBottomSheet';
import TomeCollectingBottomSheet from './TomeCollectingBottomSheet';
import { ReactComponent as CollectIcon } from '../../assets/icons/CollectIcon.svg';

const SeamEngagementBar = ({
  post,
  isOnPostDetailPage,
  isOnChannelPage,
  color,
  defaultBackground
}) => {
  const modal = useRef(null);
  const commentCount = post.get("commentCount") === 0 ? "" : post.get("commentCount");
  const totalReactions = post.get("reactionCount")
  const seamNavigator = useSeamNavigator();
  const { account, login } = useSeamUser();
  const { isMobile } = useMobile();
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [isReacting, setIsReacting] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [isCollecting, setIsCollecting] = useState(false);

  const handleCommentButtonClick = () => {
    if (!account) {
      login();
    } else if (isOnPostDetailPage) {
      // Open comment composer on PostDetailPage
      // toggleComposeBar();
    } else {
      // Navigate to PostDetailPage on feed
      seamNavigator.navigateTo(`post/${post.id}`, post);
      mixpanel.track("comments open");
    }
  };

  // Debounced function to handle like button taps
  const debouncedReactToPost = debounce((post, reactionKey, account, currentReaction) => {
    if (!isReacting) {
      setIsReacting(true);
      const onSuccess = (reaction) => {
        setSelectedReaction(reaction);
        setIsReacting(false);
      };

      TomePostRepository.reactToPost(post, reactionKey, account, currentReaction, onSuccess);
    }
  }, 300);

  const tapLikeButton = async (event) => {
    if (!account) { 
      login() 
    } else {
      setSelectedReaction(selectedReaction);
      debouncedReactToPost(post, 'Like', account, selectedReaction);
    }
  }

  const tapShareButton = async (event) => {
    if (!account) {
      login()
    } else {
      mixpanel.track("share clicked")
      setIsSharing(true);
    }
  }
  
  const tapChannelButton = async (event) => {
    if (!account) {
      event.preventDefault();
      login()
    } else {
      mixpanel.track("add to channel button clicked");
      setIsCollecting(true);
    } 
  }

  useEffect(() => {
    async function fetchCurrentReaction() {
      const currentReaction = await TomePostRepository.getReaction(post, account)
      setSelectedReaction(currentReaction)
    }

    fetchCurrentReaction()
  }, [])

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center py-1">
        <div className="flex space-x-2">
          <SeamPillButton
            icon={<ChatBubbleOutlineRounded style={{fontSize: '16px'}} className={`text-${color}`} />}
            label={
              commentCount > 0 ? 
                <h3 className={`text-${color} my-0`}>
                  {commentCount}
                </h3> 
              : null
            }
            color={color}
            defaultBackground={defaultBackground}
            onClick={handleCommentButtonClick}
            isOnChannelPage={isOnChannelPage}
          />
          <SeamPillButton
            icon={
              selectedReaction ? 
                <FavoriteOutlined style={{ fontSize: '16px' }} className="text-seam-red" /> 
              : <FavoriteBorder style={{fontSize: '16px'}} className={`text-${color}`} />
            }
            label={
              totalReactions > 0 ? 
                <h3 className={`text-${color} my-0`}>
                  {totalReactions}
                </h3> 
              : null
            }
            color={color}
            defaultBackground={defaultBackground}
            onClick={tapLikeButton}
            isOnChannelPage={isOnChannelPage}
          />
          <SeamPillButton
            icon={<StitchIcon className={`text-${color} h-4 w-4`} />}
            label={null}
            color={color}
            defaultBackground={defaultBackground}
            onClick={tapShareButton}
            isOnChannelPage={isOnChannelPage}
          />
        </div>
        {/* This spacer div will grow and push the subsequent elements to the end */}
        <div className="flex-grow"></div>
        <div className="flex space-x-2">
          <SeamPillButton 
            icon={<CollectIcon fill={color} className={`h-5 w-5 mb-1`}/>}
            label={null}
            color={color}
            defaultBackground={defaultBackground}
            onClick={tapChannelButton}
            isOnChannelPage={isOnChannelPage}
          />
        </div>
      </div>
      {(isSharing || isCollecting) && (
        <IonModal
          ref={modal}
          isOpen={isSharing || isCollecting}
          initialBreakpoint={isSharing ? 0.3 : 1}
          breakpoints={isSharing ? [0, 0.3] : [0, 1]}
          canDismiss={true}
          showBackdrop={true}
          onDidDismiss={() => {
            setIsSharing(false);
            setIsCollecting(false);
          }}
        >
          <div style={{marginBottom: 'env(safe-area-inset-bottom)'}} className="flex flex-col justify-center w-auto h-full my-6 px-4">
            {isSharing && <SeamShareBottomSheet type="post" object={post} />}
            {isCollecting && <TomeCollectingBottomSheet post={post} modalRef={modal} />}
          </div>
        </IonModal>
      )}
    </div>
  );
};

export default memo(SeamEngagementBar);